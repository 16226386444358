// export enum CodatConnectionType {
// 	AMAZON_SELLER', value:'qkdj',
// 	PAYPAL', value:'gvom',
// 	PRESTASHOP', value:'zgbz',
// 	SQUARE', value:'zsth',
// 	WOO_COMMERCE', value:'ltpp',
// 	XERO', value:'gbol',
// 	SAGE_BUSINESS_CLOUD_ACCOUNTING', value:'tgff',
// 	ZOHO_BOOKS', value:'rwuv',
// 	SUM_UP', value:'pshf',
// }
// export enum CodatKeyType {
// 	qkdj', value:'Amazon Seller Centra',
// 	gvom', value:'PayPal',
// 	zgbz', value:'PrestaShop',
// 	zsth', value:'Square',
// 	ltpp', value:'WooCommerce',
// 	gbol', value:'Xero',
// 	tgff', value:'Sage Business Cloud Accounting',
// 	rwuv', value:'Zoho Books',
// 	pshf', value:'SumUp',
// }

import { STRIPE_OAUTH_URL } from 'config';

// export enum CodatKeyType {
// 	Amazon_Seller_Centra = 'qkdj',
// 	PayPal = 'gvom',
// 	PrestaShop = 'zgbz',
// 	Square = 'zsth',
// 	WooCommerce = 'ltpp',
// 	Xero = 'gbol',
// 	Sage_Business_Cloud_Accounting = 'tgff',
// 	Zoho_Books = 'rwuv',
// 	SumUp = 'pshf',
// }
export enum CodatConnectionType {
	//commerce integrations
	AMAZON_SELLER = 'qkdj',
	AMAZON_SELLER_CENTRAL = 'qkdj',
	BIG_COMMERCE = 'vqzp',
	PRESTASHOP = 'zgbz',
	SQUARE = 'zsth',
	ZETTLE = 'ugxp',
	WOO_COMMERCE = 'ltpp',
	SUM_UP = 'pshf',
	PAYPAL = 'gvom',

	//financial/ accounting integrations
	XERO = 'gbol',
	SAGE_50_UK = 'hbql',
	SAGE_BUSINESS_CLOUD_ACCOUNTING = 'tgff',
	ZOHO_BOOKS = 'rwuv',
	CLEAR_BOOKS = 'jhch',
	EXACT_UK = 'pbbf',
	FREE_AGENT = 'fbrh',
	FRESH_BOOKS = 'vxvy',
	KASH_FLOW = 'wvzu',
	QUICK_BOOKS_DESKTOP = 'pqsw',
	QUICK_BOOKS_ONLINE = 'qhyg',

	//open banking integrations
	DEMO_BANK = 'syin',
	BARCLAY_CARD = 'qdlq',
	BARCLAYS = 'rbxl',
	BANK_OF_SCOTLAND = 'zwab',
	FIRST_DIRECT = 'zgto',
	HALIFAX = 'mbkg',
	HSBC = 'ryvk',
	LLOYDS = 'jdze',
	LLOYDS_BUSINESS = 'eyqo',
	NATIONWIDE = 'cghu',
	NATWEST = 'isdq',
	MONZO = 'trmk',
	STARLING = 'mgxb',
	RBS = 'sqqe',
	SANTANDER = 'lrfp',
	TSB = 'cebt',
	MARKS_AND_SPENCER = 'ioei',
	ULSTER_BANK_UK = 'ysyd',
	ULSTER_BANK_IRELAND = 'ektr',
	HSBC_BUSINESS = 'mhzc',
	MBNA = 'kmtu',
	REVOLUT = 'gbhu',
	BARCLAYS_BUSINESS = 'jopr',
	BANK_OF_SCOTLAND_BUSINESS = 'lvxw',
	CAPITAL_ONE = 'tqsf',
	CHELSEA_BUILDING_SOCIETY = 'bypc',
	DANSKE_BANK = 'rmdk',
	LLOYDS_COMMERCIAL = 'scxl',
	NATWEST_BUSINESS = 'ncmp',
	ROYAL_BANK_OF_SCOTLAND_BUSINESS = 'tzvy',
	TESCO_BANK = 'njvn',
	TIDE = 'cbjq',
	ULSTER_BUSINESS = 'bbmg',
	VIRGIN_MONEY = 'pqmj',
	WISE = 'jxsm',
	YORKSHIRE_BUILDING_SOCIETY = 'hhqr',
	COMDIRECT = 'pvfv',
	COMMERZ_BANK = 'zsqs',
	DEUTSCHE_BANK = 'bvsk',
	HYPO_VEREINS_BANK = 'ycdt',
	ING_GERMANY = 'dxmc',
	REVOLUT_GERMANY = 'rjbk',
	SPARKASSE = 'ckgw',
	WISE_GERMANY = 'tjjx',
	BANQUE_POPULAIRE = 'tpyh',
	BNP_PARIBAS = 'bsdr',
	CAISSE_D_EPARGNE = 'vrfk',
	CREDIT_AGRICOLE = 'zcgh',
	CREDIT_MUTUEL = 'ztlw',
	HELLO_BANK = 'vmys',
	REVOLUT_FRANCE = 'ftxj',
	SOCIETE_GENERALE = 'jwmd',
	WISE_FRANCE = 'lywm',
	ALLIED_IRISH_BANKS = 'mqqy',
	ALLIED_IRISH_BANKS_BUSINESS = 'hftm',
	BANK_OF_IRELAND = 'yhlt',
	BANK_OF_IRELAND_BUSINESS = 'lyhq',
	PERMANENT_TSB = 'pskn',
	REVOLUT_IRELAND = 'hnnk',
	WISE_IRELAND = 'hrfq',
	BANCO_DE_SABADELL = 'xjhq',
	BANCO_SANTANDER = 'wnfg',
	BANCO_BILBAO_VIZCAYA_ARGENTARIA = 'dtff',
	CAIXA = 'vksz',
	REVOLUT_SPAIN = 'zwbb',
	WISE_SPAIN = 'wbqx',
	SEB_BANK = 'pkxy',
	SWEDBANK_LITHUANIA = 'flxm',
	BANCO_BPM = 'thnn',
	INTESA_SAN_PAOLO = 'vdng',
	POSTE_ITALIANE = 'qvvk',
	REVOLUT_ITALY = 'wpkn',
	UNICREDIT = 'kblh',
	WISE_ITALY = 'ztbs',
	ING_POLAND = 'rlnb',
	M_BANK = 'slmx',
	SANTANDER_BANK_POLSKA = 'vdcw',
}
export const FINANCIAL_PLATFORM_ARRAY = [
	{
		name: 'XERO',
		value: 'gbol',
	},
	{
		name: 'SAGE_50_UK',
		value: 'hbql',
	},
	{
		name: `SAGE_BUSINESS_CLOUD_ACCOUNTING `,
		value: 'tgff',
	},
	{
		name: `ZOHO_BOOKS`,
		value: 'rwuv',
	},
	{
		name: `CLEAR_BOOKS`,
		value: 'jhch',
	},
	{
		name: `EXACT_UK`,
		value: 'pbbf',
	},
	{
		name: `FREE_AGENT`,
		value: 'fbrh',
	},
	{
		name: `FRESH_BOOKS`,
		value: 'vxvy',
	},
	{
		name: `KASH_FLOW `,
		value: 'wvzu',
	},
	{
		name: `QUICK_BOOKS_DESKTOP`,
		value: 'pqsw',
	},
	{
		name: `QUICK_BOOKS_ONLINE`,
		value: 'qhyg',
	},
];
export const ECOMMERCE_PLATFORM_ARRAY = [
	{ name: 'AMAZON_SELLER', value: 'qkdj' },
	{ name: 'BIG_COMMERCE ', value: 'vqzp' },
	{ name: 'PRESTASHOP', value: 'zgbz' },
	{ name: 'SQUARE', value: 'zsth' },
	{ name: 'WOO_COMMERCE', value: 'ltpp' },
	{ name: 'PAYPAL', value: 'gvom' },
];

export const PAYMENT_PLATFORM_ARRAY = [
	{ name: 'ZETTLE ', value: 'ugxp' },
	{ name: 'SUM_UP', value: 'pshf' },
	{
		name: 'QUICKPAY',
		connectLink:
			'https://valerian-funds.fra1.digitaloceanspaces.com/guidelines/PSP/Valerian-QuickPay%20Connection%20Guide.pdf',
		status: false,
		newTab: true,
	},
	{
		name: 'STRIPE',

		connectLink: STRIPE_OAUTH_URL,
		newTab: false,
	},
	{
		name: 'PAYPAL',

		// action: () =>
		connectLink:
			'https://valerian-funds.fra1.digitaloceanspaces.com/guidelines/PSP/Valerian-PayPal%20Connection%20Guide.pdf',
		// codatConnectionProcess(CodatConnectionType.AMAZON_SELLER),
		status: false,
		newTab: true,
	},
	{
		name: 'ADYEN',
		connectLink:
			'https://valerian-funds.fra1.digitaloceanspaces.com/guidelines/PSP/Valerian-Adyen%20Connection%20Guide.pdf',
		status: false,
		newTab: true,
	},
];

export const BANKING_PLATFORM_ARRAY = [
	// { name: 'DEMO_BANK', value: 'syin' },
	{ name: 'BARCLAY_CARD', value: 'qdlq' },
	{ name: 'BARCLAYS', value: 'rbxl' },
	{ name: 'BANK_OF_SCOTLAND', value: 'zwab' },
	{ name: 'FIRST_DIRECT', value: 'zgto' },
	{ name: 'HALIFAX', value: 'mbkg' },
	{ name: 'HSBC', value: 'ryvk' },
	{ name: 'LLOYDS', value: 'jdze' },
	{ name: 'LLOYDS_BUSINESS', value: 'eyqo' },
	{ name: 'NATIONWIDE', value: 'cghu' },
	{ name: 'NATWEST', value: 'isdq' },
	{ name: 'MONZO', value: 'trmk' },
	{ name: 'STARLING', value: 'mgxb' },
	{ name: 'RBS', value: 'sqqe' },
	{ name: 'SANTANDER', value: 'lrfp' },
	{ name: 'TSB', value: 'cebt' },
	{ name: 'MARKS_AND_SPENCER', value: 'ioei' },
	{ name: 'ULSTER_BANK_UK', value: 'ysyd' },
	{ name: 'ULSTER_BANK_IRELAND', value: 'ektr' },
	{ name: 'HSBC_BUSINESS', value: 'mhzc' },
	{ name: 'MBNA', value: 'kmtu' },
	{ name: 'REVOLUT', value: 'gbhu' },
	{ name: 'BARCLAYS_BUSINESS', value: 'jopr' },
	{ name: 'BANK_OF_SCOTLAND_BUSINESS', value: 'lvxw' },
	{ name: 'CAPITAL_ONE', value: 'tqsf' },
	{ name: 'CHELSEA_BUILDING_SOCIETY', value: 'bypc' },
	{ name: 'DANSKE_BANK', value: 'rmdk' },
	{ name: 'LLOYDS_COMMERCIAL', value: 'scxl' },
	{ name: 'NATWEST_BUSINESS', value: 'ncmp' },
	{ name: 'ROYAL_BANK_OF_SCOTLAND_BUSINESS', value: 'tzvy' },
	{ name: 'TESCO_BANK', value: 'njvn' },
	{ name: 'TIDE', value: 'cbjq' },
	{ name: 'ULSTER_BUSINESS', value: 'bbmg' },
	{ name: 'VIRGIN_MONEY', value: 'pqmj' },
	{ name: 'WISE', value: 'jxsm' },
	{ name: 'YORKSHIRE_BUILDING_SOCIETY', value: 'hhqr' },
	{ name: 'COMDIRECT', value: 'pvfv' },
	{ name: 'COMMERZ_BANK', value: 'zsqs' },
	{ name: 'DEUTSCHE_BANK', value: 'bvsk' },
	{ name: 'HYPO_VEREINS_BANK', value: 'ycdt' },
	{ name: 'ING_GERMANY', value: 'dxmc' },
	{ name: 'REVOLUT_GERMANY', value: 'rjbk' },
	{ name: 'SPARKASSE', value: 'ckgw' },
	{ name: 'WISE_GERMANY', value: 'tjjx' },
	{ name: 'BANQUE_POPULAIRE', value: 'tpyh' },
	{ name: 'BNP_PARIBAS', value: 'bsdr' },
	{ name: 'CAISSE_D_EPARGNE', value: 'vrfk' },
	{ name: 'CREDIT_AGRICOLE', value: 'zcgh' },
	{ name: 'CREDIT_MUTUEL', value: 'ztlw' },
	{ name: 'HELLO_BANK', value: 'vmys' },
	{ name: 'REVOLUT_FRANCE', value: 'ftxj' },
	{ name: 'SOCIETE_GENERALE', value: 'jwmd' },
	{ name: 'WISE_FRANCE', value: 'lywm' },
	{ name: 'ALLIED_IRISH_BANKS', value: 'mqqy' },
	{ name: 'ALLIED_IRISH_BANKS_BUSINESS', value: 'hftm' },
	{ name: 'BANK_OF_IRELAND', value: 'yhlt' },
	{ name: 'BANK_OF_IRELAND_BUSINESS', value: 'lyhq' },
	{ name: 'PERMANENT_TSB', value: 'pskn' },
	{ name: 'REVOLUT_IRELAND', value: 'hnnk' },
	{ name: 'WISE_IRELAND', value: 'hrfq' },
	{ name: 'BANCO_DE_SABADELL', value: 'xjhq' },
	{ name: 'BANCO_SANTANDER', value: 'wnfg' },
	{ name: 'BANCO_BILBAO_VIZCAYA_ARGENTARIA', value: 'dtff' },
	{ name: 'CAIXA', value: 'vksz' },
	{ name: 'REVOLUT_SPAIN', value: 'zwbb' },
	{ name: 'WISE_SPAIN', value: 'wbqx' },
	{ name: 'SEB_BANK', value: 'pkxy' },
	{ name: 'SWEDBANK_LITHUANIA', value: 'flxm' },
	{ name: 'BANCO_BPM', value: 'thnn' },
	{ name: 'INTESA_SAN_PAOLO', value: 'vdng' },
	{ name: 'POSTE_ITALIANE', value: 'qvvk' },
	{ name: 'REVOLUT_ITALY', value: 'wpkn' },
	{ name: 'UNICREDIT', value: 'kblh' },
	{ name: 'WISE_ITALY', value: 'ztbs}' },
	{ name: 'ING_POLAND', value: 'rlnb' },
	{ name: 'M_BANK', value: 'slmx' },
	{ name: 'SANTANDER_BANK_POLSKA', value: 'vdcw' },
];
