import Head from 'next/head';

interface NotFoundLayoutProps {
	title?: string;
}

const NotFoundLayout: React.FC<NotFoundLayoutProps> = ({ title }) => {
	return (
		<div className="">
			<Head>
				<title>Valerian | {title ? title : '404'}</title>

				<link rel="shortcut icon" href="/favicon.ico" />
				<link rel="icon" sizes="192x192" href="/logo192.png" />
			</Head>

			<main className="_middle my-5">
				<img src={require('../public/images/404.svg')} alt="Not Found" />
			</main>

			<footer className=""></footer>
		</div>
	);
};

export default NotFoundLayout;
