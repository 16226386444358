import { PlusCircleFilled } from '@ant-design/icons';
import { Card } from 'antd';
import { RootState } from 'dto/redux.action.dto';
// import { toUpper } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

interface Props {
	// codatConnectionProcess: (value: string) => void;
	name: any;
	codat: any;
	action?: any;
	img: any;
	connectionLink?: string;
	businessPlatform: any;
	newTab?: boolean | null;
}

const ConnectItem: React.FC<Props> = ({
	// codatConnectionProcess,
	name,
	codat,
	action,
	img,
	connectionLink,
	businessPlatform,
	newTab,
}) => {
	if (name === 'Add')
		return (
			// <img
			// 	style={{ cursor: 'pointer', padding: '24px' }}
			// 	src={img}
			// 	alt="Add"
			// 	onClick={() => action()}
			// 	className="hoverEffect"
			// />
			<Card
				hoverable
				style={{
					minWidth: '100px',
					height: '80px',
					textAlign: 'center',
					// overflow: 'hidden',
					// padding: '24px',
				}}
				onClick={() => {
					if (!connectionLink) {
						action();
					}
				}}
			>
				{/* <div> */}
				<PlusCircleFilled style={{ fontSize: '25px' }} />
				{/* </div> */}
				<br />
				<span style={{ fontSize: '10px', paddingTop: '1rem' }}>More</span>
			</Card>
		);

	return (
		<a
			href={
				connectionLink
					? !(codat?.[name] || businessPlatform?.[name]) && connectionLink
					: 'javascript:void(0)'
			}
			target={newTab ? '_blank' : '_self'}
		>
			<Card
				hoverable
				style={{
					width: '100px',
					height: '80px',
					textAlign: 'center',
					lineHeight: '100%',
					// overflow: 'hidden',
					// padding: '24px',
				}}
				onClick={() => {
					if (!connectionLink) {
						if (!(codat?.[name] || businessPlatform?.[name])) action();
					}
				}}
			>
				<img src={img} alt="logo" height={25} width={25} />
				<br />
				<b>
					{codat?.[name] || businessPlatform?.[name] ? (
						<span style={{ fontSize: '10px', color: 'green' }}>
							<b>Connected</b>
						</span>
					) : (
						<span style={{ fontSize: '10px', paddingTop: '1rem' }}>
							{name
								?.replace(/[^a-zA-Z]/g, ' ')
								.substring(0, 10)
								.toUpperCase()}
							{name?.length > 10 && '..'}
						</span>
					)}
				</b>
				<br />
			</Card>
		</a>
	);
};
const mapStateToProps = (state: RootState) => ({
	codat: state.auth.loginData.codatPlatform,
	businessPlatform: state.auth.loginData.businessPlatform,
});

export default connect(mapStateToProps)(ConnectItem);
