/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Button, Col, message, Row, Table } from 'antd';
import { StepHeader } from 'components';
import axios from 'axios';
import { BASE_URL } from 'config';
import offerStyles from '../../design/scss/allOfferComponents.module.scss';
import { connect } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import { numberWithCommas } from 'services/utils';
import { off } from 'process';

interface SignContractProps {
	clientId: string;
	// currency: string
	offer: any;
	offerId: string;
	// accessToken: string | null;
	nextBtn: (value: boolean) => void;
}

const SignContract: React.FC<SignContractProps> = ({ offer, nextBtn }) => {
	const [contractwindow, setContractwindow] = useState(true);
	function onContractClick() {
		setContractwindow(false);
		nextBtn(false);
	}
	return (
		<React.Fragment>
			<div style={{ padding: '1rem' }}>
				{/* <Row>
					<Col span={12}>
						<p className={offerStyles.eligibilityContent}>
							<strong>Eligible Amount </strong>
						</p>
					</Col>
					<Col span={12}>
						<p className={offerStyles.eligibilityContent}>
							<strong>
								{getSymbolFromCurrency(offer?.currency)}{' '}
								{numberWithCommas(offer?.initialBalance / 100)}
							</strong>
						</p>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<p className={offerStyles.eligibilityContent}>Fee</p>
					</Col>
					<Col span={12}>
						<p className={offerStyles.eligibilityContent}>
							<strong>
								{(
									(parseFloat(offer?.fee) / parseFloat(offer?.initialBalance)) *
									100
								).toFixed(2)}
								%
							</strong>
						</p>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<p className={offerStyles.eligibilityContent}>Repayment Rate </p>
					</Col>
					<Col span={12}>
						<p className={offerStyles.eligibilityContent}>
							<strong>{offer?.repaymentRate}% of revenue</strong>
						</p>
					</Col>
				</Row>
				{offer?.minRepayment && (
					<Row>
						<Col span={12}>
							<p className={offerStyles.eligibilityContent}>Minimum Repayment </p>
						</Col>
						<Col span={12}>
							<p className={offerStyles.eligibilityContent}>
								<strong>
									{getSymbolFromCurrency(offer?.offer?.currency)}{' '}
									{numberWithCommas(offer?.minRepayment / 100)}
								</strong>
							</p>
						</Col>
					</Row>
				)} */}
				{/* <Table columns={columns} dataSource={offer} size="small" /> */}
				<table style={{ width: '100%' }}>
					<tr>
						<td>Title</td>
						<td>{offer?.offer?.name}</td>
					</tr>
					<tr>
						<td>Eligible Amount</td>
						<td>
							{' '}
							{getSymbolFromCurrency(offer?.currency)}{' '}
							{numberWithCommas(offer?.initialBalance / 100)}
						</td>
					</tr>
					<tr>
						<td>Fee</td>
						<td>
							{' '}
							{(
								(parseFloat(offer?.fee) / parseFloat(offer?.initialBalance)) *
								100
							).toFixed(2)}
							%
						</td>
					</tr>
					<tr>
						<td> Repayment Rate</td> <td>{offer?.repaymentRate}% of revenue</td>
					</tr>
					{offer?.minRepayment && (
						<tr>
							<td> Minimum Repayment </td>{' '}
							<td>
								{' '}
								{getSymbolFromCurrency(offer?.currency)}{' '}
								{numberWithCommas(offer?.minRepayment / 100)}
							</td>
						</tr>
					)}
					{offer?.holidayPeriod !== 0 && (
						<tr>
							<td> Holiday Period </td>
							<td>{offer?.holidayPeriod}</td>
						</tr>
					)}
				</table>
			</div>

			<Row style={{ padding: '2rem' }} justify="start">
				<Row align="middle">
					<StepHeader description="Please sign the contract:" />
				</Row>
				{contractwindow && (
					<Col className="_full_width">
						<Button
							type="primary"
							onClick={onContractClick}
							style={{
								width: '50px',
								height: '50px',
								verticalAlign: 'middle',
								alignItems: 'center',
								float: 'right',
							}}
						>
							x
						</Button>
						<iframe
							src={offer?.pandaDocClientLink}
							width="100%"
							height="1000px"
						></iframe>
					</Col>
				)}
			</Row>
		</React.Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	clientId: state.auth.loginData.clientId,
	// accessToken: state.auth.loginData.accessToken,
	// contract: state.dash.contract,
});

// const mapDispatchToProps = (dispatch: any) => ({
// 	getContract: (offerId: string) => {
// 		dispatch(getContract(offerId));
// 	},
// });

export default connect(mapStateToProps)(SignContract);
