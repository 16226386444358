import React, { useState } from 'react';
import { Col, Row, Collapse } from 'antd';
import ConnectPlatFormModal from 'components/ConnectPlatformCard/ConnectPlatFormModal';
import FacebookModal from 'components/ConnectPlatformCard/FacebookModal';
import connectStyles from 'design/scss/connect.module.scss';
import { connect } from 'react-redux';
import { RootState } from 'dto/redux.action.dto';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

interface Props {
	facebook?: boolean;
}
const { Panel } = Collapse;
const text = `
We understand the value of your data. That is why we use your information
to provide a bespoke Offer that meets the specific needs of your business.
Valerian is fully compliant with EU GDPR and UK DPPEC regulations, and you
will always have the option to remove our access and ask us to delete your
data. For further information please see our privacy policy.
`;
const ConnectCard: React.FC<Props> = ({ facebook }) => {
	const [facebookModal, setFacebookVisible] = useState(false);

	const clickFacebook = () => {
		setFacebookVisible(true);
	};

	const handleCancel = () => {
		setFacebookVisible(false);
	};
	return (
		<div>
			{/* modals */}
			<FacebookModal googleModal={facebookModal} handleCancel={handleCancel} />
			<div>
				<h3 style={{ fontSize: '28px' }}>Connect your data platforms</h3>
				<Collapse
					accordion
					expandIconPosition={'left'}
					className={connectStyles.collapse}
					expandIcon={({ isActive }) =>
						isActive ? (
							<RightOutlined style={{ color: '#ed572f' }} />
						) : (
							<DownOutlined />
						)
					}
				>
					<Panel
						header="How we treat your data"
						key="1"
						className={connectStyles.collapseHeader}
					>
						<p className={connectStyles.customParagraph}>{text}</p>
					</Panel>
				</Collapse>
				<br />
				<div>
					<Row>
						<Col span={12}>
							<span className={connectStyles.textHeader}>Platform Type</span>
						</Col>
						<Col span={12}>
							<span className={connectStyles.textHeader}>Platforms</span>
						</Col>
					</Row>
					<hr />
					{/* Ecomm */}
					<Row>
						<Col span={12} className={connectStyles.columnsStyle}>
							<span className={connectStyles.platformHeaders}>Marketing</span>
						</Col>
						<Col span={12}>
							<span className={connectStyles.textHeader}>
								<ConnectPlatFormModal
									platforms={[
										{
											image1: require('public/images/newImages/facebookLogo.svg'),
											image2: require('public/images/newImages/facebookLogoTicked.svg'),
											action: clickFacebook,
											status: facebook,
										},
									]}
								/>
							</span>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	facebook: state.auth.loginData.marketingPlatform.FACEBOOK,
});

export default connect(mapStateToProps, null)(ConnectCard);
