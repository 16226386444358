/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Button, Checkbox, Col, Form, Input, message, Row } from 'antd';
import axios from 'axios';
import { connect } from 'react-redux';
import { contact_info_onChange } from 'store/actions/authAction';
import { BASE_URL } from 'config';
import { EMAIL_PHONE_VERIFY } from 'dto/onboarding.enum';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface Props {
	submitContact: () => void;
	changeContactInfo: (data: any) => void;
	authContact: any;
}

const ContactForm: React.FC<Props> = ({
	submitContact,
	changeContactInfo,
	authContact,
}) => {
	const [checked, setChecked] = useState(authContact.marketingPermission);

	const onFinish = async (values: any) => {
		const data = {
			email: values.email,
			phone: values.phone_prefix + values.phone,
		};
		try {
			const result = await axios.post(`${BASE_URL}auth/check-availability`, data);
			if (result.data.email === EMAIL_PHONE_VERIFY.NOT_AVAILABLE) {
				message.error('Email Already exist');
			} else if (result.data.phone === EMAIL_PHONE_VERIFY.NOT_AVAILABLE) {
				message.error('Phone already exist');
			} else if (values.phone.length < 5) {
				message.error('Phone number should be atleast 5 digits');
			} else {
				submitContact();
			}
		} catch (err) {
			message.error('Invalid Email or Phone number');
		}
	};
	return (
		<React.Fragment>
			<h3>Contact details</h3>
			<Form
				id="Registration-Form-1"
				name="basic"
				layout="vertical"
				requiredMark={false}
				onFinish={onFinish}
				onChange={(e: any) =>
					e.target.name !== 'marketingPermission' && changeContactInfo(e)
				}
				initialValues={{
					firsName: authContact.firstName,
					lastName: authContact.lastName,
					phone_prefix: authContact.phone_prefix,
					phone: authContact.phone,
					email: authContact.email,
					marketingPermission: authContact.marketingPermission,
				}}
				style={{
					textAlign: 'start',
					paddingTop: '1.6rem',
				}}
			>
				<Form.Item
					label="First Name"
					name="firsName"
					rules={[
						{
							required: true,
							message: 'Please input your first name',
						},
					]}
					style={{
						color: '#111517',
					}}
				>
					<Input name="firstName" />
				</Form.Item>

				<Form.Item
					label="Last Name"
					name="lastName"
					rules={[
						{
							required: true,
							message: 'Please input your last name',
						},
					]}
					style={{
						color: '#111517',
					}}
				>
					<Input name="lastName" />
				</Form.Item>

				<Form.Item label="Phone">
					{/* <Input.Group compact> */}
					<Row>
						<Col xs={24} sm={24} md={12} lg={12} xl={4}>
							<Form.Item
								name="phone_prefix"
								noStyle
								rules={[
									{
										required: true,
										message: 'Please input your phone prefix number',
									},
								]}
								style={{
									color: '#111517',
								}}
							>
								<PhoneInput
									country={'uk'}
									inputProps={{
										name: 'phone_prefix',
										disabled: true,
									}}
									onChange={(value) => {
										const e = {
											target: {
												name: 'phone_prefix',
												value: value,
											},
										};
										changeContactInfo(e);
									}}
									inputStyle={{ width: '100%', textAlign: 'left', borderRadius: '0px' }}
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={20}>
							<Form.Item
								name="phone"
								noStyle
								rules={[
									{
										required: true,
										message: 'Please input your phone number',
									},
									{
										pattern: /\d+/g,
										message: 'Phone number should be only digits',
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (
												getFieldValue('phone').length >= 1 &&
												getFieldValue('phone').length < 5
											) {
												return Promise.reject(
													'Phone number must be at least 5 digit long!'
												);
											}
											return Promise.resolve();
										},
									}),
								]}
							>
								<Input
									type="number"
									name="phone"
									placeholder="7700 900 251"
									style={{ height: '100%' }}
								/>
							</Form.Item>
						</Col>
					</Row>
				</Form.Item>

				<Form.Item
					label="Business Email"
					name="email"
					rules={[
						{
							required: true,
							type: 'email',
							message: 'Please input your business email',
						},
					]}
				>
					<Input name="email" type="email" placeholder="welcome@valerianfunds.com" />
				</Form.Item>
				<Form.Item name="marketingPermission">
					<Checkbox
						name="marketingPermission"
						defaultChecked={checked}
						checked={checked}
						onChange={(e: any) => {
							setChecked(e.target.checked);
							const store = {
								target: {
									name: 'marketingPermission',
									value: e.target.checked,
								},
							};
							changeContactInfo(store);
						}}
					>
						I would like to subscribe to the Valerian newsletter
					</Checkbox>
				</Form.Item>

				<Form.Item>
					<Row>
						<Col>
							<Button className="_form_submit_small" type="primary" htmlType="submit">
								Next
							</Button>
						</Col>
					</Row>
				</Form.Item>
			</Form>
		</React.Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	authContact: state.auth.contactInfo,
	mailPhoneCheck: state.auth.mailPhoneAvai,
});

const mapDispatchToProps = (dispatch: any) => ({
	changeContactInfo: (data: any) => {
		dispatch(contact_info_onChange(data));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
