import React from 'react';
import { Menu } from 'antd';
import {
	CreditCardOutlined,
	DeploymentUnitOutlined,
	FundOutlined,
	SettingOutlined,
	ShareAltOutlined,
} from '@ant-design/icons';
import dashboardStyles from 'design/scss/dashboard.module.scss';

interface MainMenuProps {
	screen: string;
}

const MainMenu: React.FC<MainMenuProps> = (props: MainMenuProps) => {
	return (
		<React.Fragment>
			<Menu
				mode="inline"
				className={
					props.screen !== 'mobile'
						? dashboardStyles.dashboard_body_sidenav_menu
						: ''
				}
			>
				<Menu.Item key="advance" icon={<FundOutlined />} disabled>
					Your advances
				</Menu.Item>
				<Menu.Item key="card" icon={<CreditCardOutlined />} disabled>
					Card Details
				</Menu.Item>
				<Menu.Item key="business" icon={<DeploymentUnitOutlined />} disabled>
					Business Intelligence Platform
				</Menu.Item>
				<Menu.Item key="platforms" icon={<ShareAltOutlined />} disabled>
					Connected Platforms
				</Menu.Item>
				<Menu.Item key="settings" icon={<SettingOutlined />} disabled>
					Settings
				</Menu.Item>
			</Menu>
		</React.Fragment>
	);
};

export default MainMenu;
