import React from 'react';
import { Layout } from 'antd';
import { MainMenu } from 'components';

const SideNav: React.FC = () => {
	return (
		<React.Fragment>
			<Layout.Sider>
				<MainMenu screen="large" />
			</Layout.Sider>
		</React.Fragment>
	);
};

export default SideNav;
