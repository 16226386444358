import React, { useEffect, useState } from 'react';
import { Row, Table, Space, Pagination } from 'antd';
import kycStyles from 'design/scss/kycCards.module.scss';
import moment from 'moment';
import { getDocType, getDocTypePage } from 'services/dashboard';
import { ClientInfo } from '../../../../../admin/src/components/ClientInfoProps/clientInfo';

interface Props {
	clientInfo: ClientInfo;
}

const KYCCard: React.FC<Props> = ({ clientInfo }) => {
	const [tableData, setTableData] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const getFiles = async () => {
		setLoading(true);
		const res = await getDocType('KYC');
		setTableData(res?.data);
		setLoading(false);
	};

	useEffect(() => {
		getFiles();
	}, []);

	const columns = [
		{
			title: 'Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (value: string) => moment(value).format('YYYY-MM-DD HH:MM'),
		},
		{
			title: 'Title',
			dataIndex: 'fileType',
			key: 'fileType',
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			render: (value: string, row: any) => {
				let str = row.url.split('-').pop();
				if (value === 'contractReupload') {
					return `Contract Signed Doc ${str.substring(0, str.indexOf('.'))}`;
				} else if (value === 'businessInfo') {
					return `FINANCIALS ${str.substring(0, str.indexOf('.'))}`;
				} else {
					return `${value.toUpperCase()} ${String(
						str.substring(0, str.indexOf('.'))
					).replace(/[^a-zA-Z0-9]/g, '')}`;
				}
			},
		},
		//action
		{
			title: 'Action',
			dataIndex: 'url',
			key: 'action',
			render: (url: string) => (
				<Space size="middle">
					<a href={url}>{'Download >'}</a>
				</Space>
			),
		},
	];

	const pageOnChange = async (page: number) => {
		setLoading(true);

		getDocTypePage('KYC', page)
			.then((data) => {
				setTableData(data.data);
				setLoading(false);
			})
			.catch(() => {
				setTableData([]);
				setLoading(false);
			});
	};

	const data = [];
	for (let i = 0; i < 100; i++) {
		data.push({
			key: i,
			date: `March 12 2021 ${i}`,
			title: `Passport Scan ${i}`,
		});
	}
	return (
		<>
			<div className={kycStyles.infoContainer}>
				<Row justify="space-between">
					<span className={kycStyles.infoContainer_half_title}>First Name</span>
					<span className={kycStyles.infoContainer_half}>
						{clientInfo !== null && clientInfo.kyc.firstName}
					</span>
				</Row>
				<Row justify="space-between">
					<span className={kycStyles.infoContainer_half_title}>Last Name</span>
					<span className={kycStyles.infoContainer_half}>
						{clientInfo !== null && clientInfo.lastName}
					</span>
				</Row>
				<Row justify="space-between">
					<span className={kycStyles.infoContainer_half_title}>Role</span>
					<span className={kycStyles.infoContainer_half}>
						{' '}
						{clientInfo !== null && clientInfo.kyc.companyName}
					</span>
				</Row>
				<Row justify="space-between">
					<span className={kycStyles.infoContainer_half_title}>Phone</span>
					<span className={kycStyles.infoContainer_half}>
						{clientInfo !== null && clientInfo.phone}
					</span>
				</Row>
				<Row justify="space-between">
					<span className={kycStyles.infoContainer_half_title}>Email</span>
					<span className={kycStyles.infoContainer_half}>
						{clientInfo !== null && clientInfo.email}
					</span>
				</Row>
				<Row>
					<Table
						columns={columns}
						dataSource={tableData?.docs}
						className={kycStyles.infoContainer_table}
						pagination={false}
						loading={loading}
					/>
					<Pagination
						style={{ float: 'right' }}
						current={tableData?.page}
						total={tableData?.totalPages * 10}
						onChange={pageOnChange}
					/>
				</Row>
			</div>
		</>
	);
};

export default KYCCard;
