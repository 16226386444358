/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { Card, Select, Row } from 'antd';
import { connect } from 'react-redux';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Legend,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
} from 'recharts';
import { getGraph } from 'services/dashboard';
import moment from 'moment';
import { RootState } from '../../../dto/redux.action.dto';

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dash: any;
}

const { Option } = Select;

const ClientDashChart: React.FC<Props> = ({ dash }) => {
	const [selectedAdvance, setSelectedAdvance] = useState(null);
	const [graphData, setGraphData] = useState(null);
	const { advance } = dash;

	const onChange = (value: string) => {
		advance.map((info: { advanceId: string }) => {
			if (info.advanceId === value) {
				setSelectedAdvance(info);
			}
		});
	};
	useEffect(() => {
		setInitialAdvance();
	}, [advance]);

	useEffect(() => {
		if (selectedAdvance !== null) {
			getGraphData(selectedAdvance.advanceId);
		}
	}, [selectedAdvance]);

	const getGraphData = async (advanceId: string) => {
		const data = await getGraph(advanceId);
		setGraphData(data);
	};

	const setInitialAdvance = () => {
		if (advance.length > 0) {
			setSelectedAdvance(advance[0]);
		}
	};

	let finalData = [];
	if (graphData !== null && graphData?.data) {
		if (graphData.data.length > 0) {
			finalData = graphData.data.map(
				(data: {
					_id: moment.MomentInput;
					repaymentRev: number;
					totalRev: number;
				}) => {
					let date = data._id;
					if (graphData.type === 'monthly') {
						date = moment(data._id).format('MMMM');
					} else if (graphData.type === 'weekly') {
						date =
							moment(data._id).weeks() -
							moment(data._id).add(0, 'month').startOf('month').weeks() +
							1;
					}
					return {
						name: date,
						repayed: Math.round(data.repaymentRev / 100),
						totalRev: Math.round(data.totalRev / 100 - data.repaymentRev / 100),
					};
				}
			);
		}
	}

	return (
		<>
			{graphData !== null ? (
				graphData.data.length > 0 ? (
					<div style={{ width: '100%' }}>
						<Card
							title={<h3>Overview of all Repayments</h3>}
							bordered={false}
							style={{ width: '100%' }}
						>
							<Row justify="end">
								<Select
									defaultValue={advance.length > 0 ? advance[0].advanceId : ''}
									style={{ width: 200 }}
									onChange={onChange}
								>
									{advance.length > 0 &&
										advance.map((info: { advanceId: string }) => (
											<Option value={info.advanceId}>{info.advanceId}</Option>
										))}
								</Select>
							</Row>
							<br />

							<>
								<Row>
									<p>{graphData !== null && graphData.type.toUpperCase()}</p>
								</Row>
								<Row justify="center">
									<ResponsiveContainer width={'99%'} height={300}>
										<BarChart
											width={700}
											height={300}
											data={finalData}
											margin={{
												top: 20,
												right: 30,
												left: 20,
												bottom: 5,
											}}
										>
											<CartesianGrid strokeDasharray="2 2" />
											<XAxis dataKey="name" />
											<YAxis />
											<Tooltip />
											<Legend />
											<Bar
												dataKey="totalRev"
												name="Retained Revenue"
												stackId="a"
												fill="#1a3580"
											/>
											<Bar
												dataKey="repayed"
												name="Repayment Amount"
												stackId="a"
												fill="#ed572f"
											/>
										</BarChart>
									</ResponsiveContainer>
								</Row>
							</>
						</Card>
					</div>
				) : (
					<></>
				)
			) : (
				<></>
			)}
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	dash: state.dash,
});

export default connect(mapStateToProps)(ClientDashChart);
