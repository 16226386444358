/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { BASE_URL } from 'config';
import { StepHeader } from 'components';
import { Row } from 'antd';
import { RootState } from 'dto/redux.action.dto';
import {
	setRepaymentGateway,
	setRepaymentGatewayString,
} from 'store/actions/dashBoardActions';

interface RepaymentProps {
	accessToken: string;
	clientId: string;
	setUrl: (value: string) => void;
	setRepaymentGateway: (value: any[]) => void;
	setRepaymentGatewayString: (gateway: string, advanceId: string) => void;
}

const Repayment: React.FC<RepaymentProps> = (props: RepaymentProps) => {
	// const [advanceList, setAdvanceList] = useState([]);

	const getLink = async () => {
		const options = {
			headers: {
				Authorization: `Bearer ${props.accessToken}`,
				'Content-Type': 'application/json',
			},
		};

		await axios
			.get(`${BASE_URL}advance/client-advance-list?status=CREATED`, options)
			.then(async (res: any) => {
				if (res.data.length === 1) {
					const { repaymentGateway, advanceId } = res.data[0];
					props.setRepaymentGatewayString(repaymentGateway, advanceId);
					props.setRepaymentGateway(res.data);
					const body = {
						repaymentGateway,
					};

					const data = await axios.post(
						`${BASE_URL}connect/gocardless/getLink`,
						body,
						options
					);
					props.setUrl(data.data.url);
				} else if (res.data.length > 1) {
					props.setRepaymentGateway(res.data);
				}
			});
	};

	useEffect(() => {
		getLink();
	}, []);

	return (
		<React.Fragment>
			<Row
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					textAlign: 'center',
				}}
			>
				<StepHeader description="Please click below to securely provide you bank details. This will allow us to arrange automatic repayments." />
			</Row>
		</React.Fragment>
	);
};

const mapStateToProps = (state: RootState) => ({
	accessToken: state.auth.loginData.accessToken,
	clientId: state.auth.loginData.clientId,
});

const mapDispatchToProps = (dispatch: any) => ({
	setRepaymentGateway: (repaymentGateway: any[]) => {
		dispatch(setRepaymentGateway(repaymentGateway));
	},
	setRepaymentGatewayString: (gateway: string, advanceId: string) => {
		dispatch(setRepaymentGatewayString(gateway, advanceId));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(Repayment);
