import React, { useEffect, useState } from 'react';
import { Steps, Card, Select, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ClientDashboardStepsStyles from '../../../design/scss/dashboard.module.scss';
import { connect } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import { RootState } from 'dto/redux.action.dto';
interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dash: any;
	currency: string;
}
const { Step } = Steps;
const { Option } = Select;

const ClientProgressSteps: React.FC<Props> = ({ dash, currency }) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [selectedAdvance, setSelectedAdvance] = useState<any>(null);
	const { advance } = dash;

	const onChange = (value: string) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		advance.map((info: any) => {
			if (info.advanceId === value) {
				setSelectedAdvance(info);
			}
		});
	};

	useEffect(() => {
		setInitialAdvance();
	}, [advance]);

	const setInitialAdvance = () => {
		if (advance && advance.length > 0) {
			setSelectedAdvance(advance[0]);
		}
	};

	return advance ? (
		<Card
			bordered={false}
			className={ClientDashboardStepsStyles.Stepper_card}
			style={{ width: '100%' }}
		>
			<Row justify="end">
				<Select
					defaultValue={advance.length > 0 ? advance[0].advanceId : ''}
					style={{ width: 200 }}
					onChange={onChange}
				>
					{/* <Option value="option 1">option 1</Option>
          <Option value="option 2">option 2</Option>
          <Option value="option 3">option 3</Option> */}
					{advance.length > 0 &&
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						advance.map((info: any) => (
							<Option value={info.advanceId}>{info.advanceId}</Option>
						))}
				</Select>
			</Row>
			<br />

			<Steps
				progressDot
				current={
					selectedAdvance !== null
						? selectedAdvance.totalRepaid === 0
							? 0
							: selectedAdvance.totalRepaid === selectedAdvance.initialBalance
							? 2
							: 1
						: 0
				}
				className={ClientDashboardStepsStyles.Stepper_steps}
			>
				<Step
					title="Total Amount Advanced"
					description={
						selectedAdvance !== null &&
						`${getSymbolFromCurrency(currency)} ${String(
							(selectedAdvance.initialBalance + selectedAdvance.fee) / 100
						).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
					}
				/>
				<Step
					title="Amount Repaid"
					description={
						selectedAdvance !== null &&
						`${getSymbolFromCurrency(currency)} ${String(
							selectedAdvance.totalRepaid / 100
						).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
					}
				/>
				<Step
					title="Amount Outstanding"
					description={
						selectedAdvance !== null &&
						`${getSymbolFromCurrency(currency)} ${String(
							selectedAdvance.totalAmountOutstanding / 100
						).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
					}
				/>
			</Steps>
		</Card>
	) : (
		<Spin indicator={<LoadingOutlined className="_spin" spin />} />
	);
};

const mapStateToProps = (state: RootState) => ({
	dash: state.dash,
	currency: state.auth.loginData.currency,
});

export default connect(mapStateToProps)(ClientProgressSteps);
