import React, { useEffect, useState } from 'react';
import { Row, Table, Space, Pagination } from 'antd';
import DocumentStyle from '../../../design/scss/documentsCards.module.scss';
import { getAllDocuments, onDocumentPageChange } from 'services/dashboard';
import moment from 'moment';

const DocumentCard: React.FC = () => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [tableData, setTableData] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const columns = [
		{
			title: 'Date',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (value: string) => moment(value).format('YYYY-MM-DD HH:MM'),
		},
		{
			title: 'Title',
			dataIndex: 'fileType',
			key: 'fileType',
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			render: (value: string, row: any) => {
				let str = row.url.split('--').pop();

				if (value === 'contractReupload') {
					return `Contract Signed Doc ${String(
						str.substring(0, str.indexOf('.'))
					).replace(/[^a-zA-Z0-9]/g, '')}`;
				} else if (value === 'businessInfo') {
					return `FINANCIALS ${String(str.substring(0, str.indexOf('.'))).replace(
						/[^a-zA-Z0-9]/g,
						''
					)}`;
				} else {
					return `${value.toUpperCase()} ${String(
						str.substring(0, str.indexOf('.'))
					).replace(/[^a-zA-Z0-9]/g, '')}`;
				}
			},
		},
		//action
		{
			title: 'Action',
			dataIndex: 'url',
			key: 'action',
			render: (url: string) => (
				<Space size="middle">
					<a href={url}>{'Download>'}</a>
				</Space>
			),
		},
	];

	useEffect(() => {
		getFiles();
	}, []);

	const getFiles = async () => {
		setLoading(true);
		const res = await getAllDocuments();
		setTableData(res.data);
		setLoading(false);
	};

	const data = [];
	for (let i = 0; i < 100; i++) {
		data.push({
			key: i,
			date: `March 12 2021 ${i}`,
			title: `Financials March 2021 ${i}`,
		});
	}
	const pageOnChange = async (page: number) => {
		setLoading(true);

		onDocumentPageChange(page)
			.then((data) => {
				setTableData(data.data);
				setLoading(false);
			})
			.catch(() => {
				setTableData([]);
				setLoading(false);
			});
	};

	return (
		<>
			<div>
				<Row className={DocumentStyle.infoContainer}>
					<Table
						columns={columns}
						dataSource={tableData ? tableData.docs : []}
						className={DocumentStyle.infoContainer_table}
						pagination={false}
						loading={loading}
					/>
					<Pagination
						style={{ float: 'right' }}
						current={tableData.page}
						total={tableData.totalPages * 10}
						onChange={pageOnChange}
					/>
				</Row>
			</div>
		</>
	);
};

export default DocumentCard;
