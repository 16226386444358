import React, { useState } from 'react';
import { Button, Col, Drawer, Menu, Row } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import dashboardStyles from 'design/scss/dashboard.module.scss';
import navStyles from 'design/scss/navbarComponents.module.scss';
import { MainMenu } from 'components';
import { connect } from 'react-redux';
import SubMenu from 'antd/lib/menu/SubMenu';
import { handleLogout } from 'store/actions/authAction';
import { clearData } from 'store/actions/dashBoardActions';
import { RootState } from 'dto/redux.action.dto';

interface TopNavProps {
	email: string;
	handleLogout: () => void;
}

const TopNav: React.FC<TopNavProps> = ({ email, handleLogout }) => {
	const [visible, setVisible] = useState(false);

	const ShowDrawer = () => {
		setVisible(true);
	};
	const CloseDrawer = () => {
		setVisible(false);
	};

	return (
		<React.Fragment>
			<div>
				<Drawer
					title={email}
					placement="right"
					closable={true}
					onClose={CloseDrawer}
					visible={visible}
					bodyStyle={{ padding: '0px' }}
				>
					<MainMenu screen="mobile" />
					<Menu mode="inline">
						<SubMenu key="sub1" title={'email'}>
							<Menu.ItemGroup key="g1">
								<Menu.Item key="logout" onClick={() => handleLogout()}>
									LOGOUT
								</Menu.Item>
							</Menu.ItemGroup>
						</SubMenu>
					</Menu>
				</Drawer>

				<Row justify="space-between">
					<Col span={12}>
						<Row justify="start" align="middle">
							<span className={navStyles.r2s2}>
								<img
									src={'/luca.png'}
									alt="Valerian Funds"
									className={dashboardStyles.dashboard_topnav_logo}
								/>
							</span>
						</Row>
					</Col>
					<Col span={12} className="responsivehambugerIcon">
						<Button
							className={dashboardStyles.dashboard_mobilenav}
							type="default"
							icon={<MenuOutlined />}
							onClick={ShowDrawer}
						/>

						<Menu mode="horizontal" className={dashboardStyles.dashboard_topnav_menu}>
							<SubMenu key="sub1" title={email}>
								<Menu.ItemGroup key="g1">
									<Menu.Item key="logout" onClick={() => handleLogout()}>
										LOGOUT
									</Menu.Item>
								</Menu.ItemGroup>
							</SubMenu>
						</Menu>
					</Col>
				</Row>
			</div>
		</React.Fragment>
	);
};

const mapStateToProps = (state: RootState) => ({
	email: state.auth.loginData.email,
});

const mapDispatchToProps = (dispatch: any) => ({
	handleLogout: () => {
		dispatch(handleLogout());
		dispatch(clearData());
	},
});
export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
