import { Card, Col, Modal, Row, Spin } from 'antd';
import Search from 'antd/lib/input/Search';
import { RootState } from 'dto/redux.action.dto';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getCodatConnection } from 'services/dashboard';

interface Props {
	visible: boolean;
	handleCancel: () => void;
	title: string;
	allData: {
		name: string;
		value: string;
		connectLink?: string;
		newTab?: boolean;
	}[];
	codat: any;
}

const AddModal: React.FC<Props> = ({
	visible,
	handleCancel,
	title,
	allData,
	codat,
}) => {
	const [search, setSearch] = useState('');
	const [loading, setLoading] = useState(false);
	const data = allData?.filter((item) => item.name.includes(search)).slice(0, 6);

	const codatConnectionProcess = async (data: string) => {
		setLoading(true);
		await getCodatConnection(data);
		setLoading(false);
	};

	return (
		<Modal
			visible={visible}
			onCancel={handleCancel}
			footer={false}
			confirmLoading={loading}
		>
			<Row justify="center">
				<Search
					placeholder={`Search your ${title} service provider`}
					allowClear
					style={{ padding: '2rem', width: '40rem' }}
					value={search}
					onChange={(event: any) =>
						setSearch(String(event.target.value).toUpperCase())
					}
				/>
			</Row>
			{!loading ? (
				<Row justify="center">
					<Col>
						<Row justify="start">
							{data?.map((item: any) => (
								<Col
									style={{
										padding: '1rem',
									}}
								>
									<a
										href={item?.connectLink && item?.connectLink}
										target={item?.newTab && '_blank'}
									>
										<Card
											hoverable
											style={{
												// fontSize: '9px',
												width: '12rem',
												height: '10rem',
												textAlign: 'center',
											}}
											onClick={() => {
												if (!item?.connectLink) {
													codatConnectionProcess(item.value);
												}
											}}
										>
											<b style={{ textAlign: 'center' }}>
												{item?.name.replace(/[^a-zA-Z ]/g, ' ').substring(0, 12)}{' '}
												{item?.name?.length > 12 && '..'}
											</b>
											<br />
											<span style={{ fontSize: '10px', color: 'green' }}>
												{codat?.[item.name] && 'Connected'}
											</span>
										</Card>
									</a>
								</Col>
							))}
						</Row>
					</Col>
				</Row>
			) : (
				<Row justify="center" style={{ height: '20rem', alignItems: 'center' }}>
					<Spin />
				</Row>
			)}
		</Modal>
	);
};

const mapStateToProps = (state: RootState) => ({
	codat: state.auth.loginData.codatPlatform,
});

export default connect(mapStateToProps)(AddModal);
