import React from 'react';
import { Row, Breadcrumb } from 'antd';
import breadCrumStyles from '../../design/scss/breadCrums.module.scss';
import { connect } from 'react-redux';
import { useRouter } from 'next/router';

interface Props {
	companyName: string;
}

const CustomBreadCrum: React.FC<Props> = ({ companyName }) => {
	const router = useRouter();
	return (
		<div className={breadCrumStyles.TopSection}>
			<Row justify={'space-between'} align={'middle'}>
				<Row>
					<span>
						<h3>
							<b>{companyName}</b>
						</h3>
					</span>
				</Row>
				<Breadcrumb className={breadCrumStyles.TopSection_breadcrums}>
					<Breadcrumb.Item>
						<p>{router.pathname}</p>
					</Breadcrumb.Item>
				</Breadcrumb>
			</Row>
		</div>
	);
};
const mapStateToProps = (state: {
	auth: { loginData: { companyName: string } };
}) => ({
	companyName: state.auth.loginData.companyName,
});

export default connect(mapStateToProps)(CustomBreadCrum);
