import Router from 'next/router';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { message } from 'antd';
import axios from 'axios';
import { BASE_URL } from 'config';
import { store } from 'store';

export const getAdvanceData = async () => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	const data = {
		clientId: store.getState().auth.loginData.clientId,
	};
	try {
		const res = await axios.post(
			`${BASE_URL}advance/client-advance`,
			data,
			options
		);
		const progressValue = [
			{ title: 'Loan Provided', desc: 'Lorem Ipsum Dotor' },
			{
				title: `EUR ${res.data[0].totalRepaid} repaid`,
				desc: 'You are here now',
			},
			{
				title: `You owe Valerian EUR ${
					res.data[0].initialBalance + parseFloat(res.data[0].fee)
				}`,
				desc: 'This is a description.',
			},
		];
		return progressValue;
	} catch (err) {
		return err;
	}
};

export const getRepaymentData = async () => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.get(`${BASE_URL}revenue/client-rev`, options);
		return result;
	} catch (err) {
		return err;
	}
};

export const getAdvanceFullData = async () => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.get(
			`${BASE_URL}advance/client-advance-list`,
			options
		);
		return result;
	} catch (err) {
		return err;
	}
};

export const getOfferInfo = async (id: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.get(`${BASE_URL}offer/client/${id}`, options);
		return result;
	} catch (err) {
		return err;
	}
};

export const getClient = async () => {
	// Option for the axios request
	try {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		const res = await axios.get(`${BASE_URL}client/info`, options);
		return res;
	} catch (err) {
		return err;
	}
};

export const getAllDocuments = async () => {
	//Option for the axios request
	try {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		const res = await axios.get(`${BASE_URL}file/list?limit=5`, options);
		return res;
	} catch (err) {
		return err;
	}
};

export const onDocumentPageChange = async (page: number) => {
	try {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		const res = await axios.get(
			`${BASE_URL}file/list?page=${page}&limit=5`,
			options
		);
		return res;
	} catch (err) {
		return err;
	}
};

export const getDocType = async (type: string) => {
	//Option for the axios request
	try {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		const res = await axios.get(
			`${BASE_URL}file/list?fileType=${type}&limit=5`,
			options
		);
		return res;
	} catch (err) {
		return err;
	}
};

export const getDocTypePage = async (type: string, page: number) => {
	try {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		const res = await axios.get(
			`${BASE_URL}file/list?fileType=${type}&page=${page}&limit=5`,
			options
		);
		return res;
	} catch (err) {
		return err;
	}
};

export const getGraph = async (advanceId: string) => {
	try {
		const options = {
			headers: {
				Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		const data = {
			clientId: store.getState().auth.loginData.clientId,
			advanceId: advanceId,
		};
		const res = await axios.post(`${BASE_URL}revenue/graph`, data, options);
		return res.data;
	} catch (err) {
		return err;
	}
};

export const updateForceState = async (state: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const data = { clientId: store.getState().auth.loginData.clientId, state };
		await axios.post(`${BASE_URL}client/remove-force-state`, data, options);
		message.success('Thank you for fulfilling the request');
		return true;
	} catch (err) {
		message.error('Force state could not be Fulfilled');
		return false;
	}
};

export const generateReportData = async (
	values: { fromDate: string; toDate: string } | null,
	field: string,
	clientId: string
) => {
	const options = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
		},
	};

	//${BASE_URL}report/client/generate
	if (values === null) {
		let body;
		clientId
			? (body = {
					reportType: field,
					clientId: clientId,
			  })
			: (body = { reportType: field });
		try {
			const { data } = await axios.post(
				`${BASE_URL}report/client/generate`,
				body,
				options
			);

			message.success(`Report has been sent to your email address`);
			return data;
		} catch (err) {
			message.error('Report generation failed');
		}
	} else {
		const body = {
			reportType: field,
			fromDate: values.fromDate,
			toDate: values.toDate,
			clientId: clientId,
		};

		try {
			const { data } = await axios.post(
				`${BASE_URL}report/client/generate`,
				body,
				options
			);

			if (data.status === 405) {
				message.error('There is no data between this timeline');
			}
			if (data.status === 200) {
				message.success(`Report has been sent to your email address`);
			}

			return data;
		} catch (err) {
			message.error('Report generation failed');
		}
	}
};

export const getCodatConnection = async (type: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		const result = await axios.post(
			`${BASE_URL}connect/codat/link`,
			{ integrationName: type },
			options
		);
		location.href = result.data.linkUrl;
	} catch (err) {
		message.error('Connection failed', err);
	}
};

export const sendCodatUpdateReq = async (code: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		await axios.put(
			`${BASE_URL}client/update/platform`,
			{ platform: code },
			options
		);
		message.success('You have succesfully added the platform');
		Router.push('/onboarding');
	} catch (err) {
		message.error('Connection failed', err);
	}
};
