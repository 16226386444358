import React from 'react';
import { Row } from 'antd';
import connectedCardStyle from 'design/scss/connectedCards.module.scss';
import { connect } from 'react-redux';
import { RootState } from 'dto/redux.action.dto';

//payment array icons
import stripeImage from 'public/images/newImages/stripe.svg';
// import stripeticked from 'public/images/newImages/stripeTicked.svg';
import paypal from 'public/images/newImages/paypalGrey.svg';
import adyen from 'public/images/newImages/adyengrey.svg';
import quickplay from 'public/images/newImages/quickplaygrey.svg';

import shopifyImage from 'public/images/newImages/shopify.svg';
import shopifyTicked from 'public/images/newImages/shopifyTicked.svg';
import prestashopImageGrey from 'public/images/newImages/prestashopGrey.svg';

import amazonImage from 'public/images/newImages/amazongrey.svg';
import wooComImage from 'public/images/newImages/woogrey.svg';

import googleAdsImage from 'public/images/newImages/googleAds.svg';
import googleAnalyticsImage from 'public/images/newImages/googleAnalytics.svg';
import facebookLogo from 'public/images/newImages/facebookLogo.svg';
// import paypalTicked from 'public/images/newImages/paypalTicked.svg';
// import prestashopTicked from 'public/images/newImages/prestashopTicked.svg';
// import amazonImageTicked from 'public/images/newImages/amazonticked.svg';
// import wooComImageTicked from 'public/images/newImages/wooticked.svg';
// import googleAdsTicked from 'public/images/newImages/googleAdsTicked.svg';
// import googleAnalyticsTicked from 'public/images/newImages/googleAnalyticsTicked.svg';
// import facebookLogoTicked from 'public/images/newImages/facebookLogoTicked.svg';

interface Props {
	stripe: boolean;
	shopify: boolean;
}

const ConnectedCard: React.FC<Props> = ({ stripe, shopify }) => {
	return (
		<>
			<div className={connectedCardStyle.container}>
				<p>PSP</p>
				<Row className={connectedCardStyle.imageHolders}>
					{stripe ? (
						<img src={shopifyTicked} alt="shopifyTicked" />
					) : (
						<img src={stripeImage} alt="stripeImage" />
					)}

					<img src={paypal} alt="paypal" />
					<img src={adyen} alt="adyen" />
					<img src={quickplay} alt="quickplay" />
				</Row>
				<p>E-commerce</p>
				<Row className={connectedCardStyle.imageHolders}>
					{shopify ? (
						<img src={shopifyTicked} alt="shopifyTicked" />
					) : (
						<img src={shopifyImage} alt="shopifyImage" />
					)}

					<img src={prestashopImageGrey} alt="prestashopImageGrey" />
					<img src={amazonImage} alt="amazonImage" />
					<img src={wooComImage} alt="wooComImage" />
				</Row>
				<p>Marketing</p>
				<Row className={connectedCardStyle.imageHolders}>
					<img src={googleAdsImage} alt="googleAdsImage" />
					<img src={googleAnalyticsImage} alt="googleAnalyticsImage" />
					<img src={facebookLogo} alt="facebookLogo" />
				</Row>
			</div>
		</>
	);
};

const mapStateToProps = (state: RootState) => ({
	stripe: state.auth.loginData.businessPlatform.STRIPE,
	shopify: state.auth.loginData.businessPlatform.SHOPIFY,
});

export default connect(mapStateToProps)(ConnectedCard);
