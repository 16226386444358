import React, { useState } from 'react';
import { Button, Col, Row, Collapse, Spin } from 'antd';
import ConnectPlatFormCard from '../ConnectPlatformCard/ConnectPlatFormCard';
import ConnectPlatFormModal from 'components/ConnectPlatformCard/ConnectPlatFormModal';
import ShopifyModal from 'components/ConnectPlatformCard/ShopifyModal';
import GoogleModal from 'components/ConnectPlatformCard/GoogleModal';
import GoogleAnalyticsModal from 'components/ConnectPlatformCard/GoogleAnalyticsModal';
import FacebookModal from 'components/ConnectPlatformCard/FacebookModal';
import connectStyles from 'design/scss/connect.module.scss';
import {
	STRIPE_OAUTH_URL,
	GOOGLE_ADS_CLIENT_ID,
	GOOGLE_ADS_REDIRECT_URI,
} from 'config';
import { connect } from 'react-redux';
import { updateClientCurrentState } from 'store/actions/authAction';
import { RootState } from 'dto/redux.action.dto';
import OtherChoiceModal from 'components/ConnectPlatformCard/OtherChoiceModal';

//payment array icons
import stripeImage from 'public/images/newImages/stripe.svg';
import stripeticked from 'public/images/newImages/stripeTicked.svg';
import paypalTicked from 'public/images/newImages/paypalTicked.svg';
import paypal from 'public/images/newImages/paypal.svg';
import adyen from 'public/images/newImages/adyen.svg';

import shopifyImage from 'public/images/newImages/shopify.svg';
import shopifyTicked from 'public/images/newImages/shopifyTicked.svg';
import prestashopImageGrey from 'public/images/newImages/prestashop.svg';
import prestashopTicked from 'public/images/newImages/prestashopTicked.svg';

import amazonImage from 'public/images/newImages/amazon.svg';
import amazonImageTicked from 'public/images/newImages/amazonticked.svg';

import googleAdsImage from 'public/images/newImages/googleAds.svg';
import googleAnalyticsImage from 'public/images/newImages/googleAnalytics.svg';
import googleAnalyticsTicked from 'public/images/newImages/googleAnalyticsTicked.svg';
import facebookLogo from 'public/images/newImages/facebookLogo.svg';
import facebookLogoTicked from 'public/images/newImages/facebookLogoTicked.svg';

import BetaTicked from 'public/images/newImages/BetaTicked.svg';

import AddButton from 'public/images/newImages/Button.svg';

// dto
import {
	PaymentarrayProps,
	MarketingAndEcommarrayProps,
	EcommarrayProps,
} from 'components/dto/dto';
import {
	DownOutlined,
	LoadingOutlined,
	RightOutlined,
} from '@ant-design/icons';
import {
	CodatConnectionType,
	ECOMMERCE_PLATFORM_ARRAY,
	PAYMENT_PLATFORM_ARRAY,
} from 'utils/variables';
import { getCodatConnection } from 'services/dashboard';
import AddModal from 'components/ConnectPlatformCard/AddModal';

interface Props {
	stripe: boolean | null;
	shopify: boolean | null;
	facebook: boolean | null;
	googleAdsBeta: boolean | null;
	updateClientCurrentState: (state: string, force: boolean) => void;
}

const { Panel } = Collapse;
const text = `
We understand the value of your data. That is why we use your information
to provide a bespoke Offer that meets the specific needs of your business.
Valerian is fully compliant with EU GDPR and UK DPPEC regulations, and you
will always have the option to remove our access and ask us to delete your
data. For further information please see our privacy policy.
`;

const ConnectCard: React.FC<Props> = ({
	stripe,
	facebook,
	shopify,
	googleAdsBeta,
	updateClientCurrentState,
}) => {
	const [shopifyModal, setShopifyVisible] = useState(false);
	const [googleModal, setGoogleVisible] = useState(false);
	const [googleAnalyticsModal, setGoogleAnalyticsVisible] = useState(false);
	const [facebookModal, setFacebookVisible] = useState(false);
	const [otherChoiceModal, setOtherChoiceModal] = useState(false);
	const [addModal, setAddModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [platform, setPlatform] = useState(null);

	const showOtherChoiceModal = () => {
		setOtherChoiceModal(true);
	};

	const codatConnectionProcess = async (data: CodatConnectionType) => {
		setLoading(true);
		await getCodatConnection(data);
		setLoading(false);
	};

	const clickShopify = () => {
		setShopifyVisible(true);
	};
	const clickGoogle = () => {
		setGoogleVisible(true);
	};
	const clickGoogleAds = () => {
		setGoogleAnalyticsVisible(true);
	};

	const clickFacebook = () => {
		setFacebookVisible(true);
	};
	const clickAdd = (platform: any) => {
		setPlatform(platform);
		setAddModal(true);
	};

	const handleCancel = () => {
		setShopifyVisible(false);
		setGoogleVisible(false);
		setGoogleAnalyticsVisible(false);
		setFacebookVisible(false);
		setOtherChoiceModal(false);
	};

	const handleNext = () => {
		updateClientCurrentState('FINANCIAL', false);
	};

	const PaymentsArray: PaymentarrayProps[] = [
		{
			name: 'STRIPE',
			image1: stripeImage,
			image2: stripeticked,
			connectLink: STRIPE_OAUTH_URL,
			status: stripe,
			newTab: false,
		},
		{
			name: 'PAYPAL',
			image1: paypal,
			image2: paypalTicked,
			// action: () =>
			connectLink:
				'https://valerian-funds.fra1.digitaloceanspaces.com/guidelines/PSP/Valerian-PayPal%20Connection%20Guide.pdf',
			// codatConnectionProcess(CodatConnectionType.AMAZON_SELLER),
			status: false,
			newTab: true,
		},
		{
			name: 'ADYEN',
			image1: adyen,
			image2: adyen,
			connectLink:
				'https://valerian-funds.fra1.digitaloceanspaces.com/guidelines/PSP/Valerian-Adyen%20Connection%20Guide.pdf',
			status: false,
			newTab: true,
		},
		{
			name: 'Add',
			image1: AddButton,
			image2: AddButton,
			action: () => clickAdd(PAYMENT_PLATFORM_ARRAY),
			status: null,
			connectLink: '',
			// 'https://valerian-funds.fra1.digitaloceanspaces.com/guidelines/eCommerce/Valerian-WooCommerce%20Connection%20Guide.pdf',
			newTab: null,
		},
	];

	const EcommArray: EcommarrayProps[] = [
		{
			name: 'SHOPIFY',
			image1: shopifyImage,
			image2: shopifyTicked,
			action: clickShopify,
			status: shopify,
			connectLink: '',
			newTab: null,
		},
		{
			name: 'PRESTASHOP',
			image1: prestashopImageGrey,
			image2: prestashopTicked,
			action: () => codatConnectionProcess(CodatConnectionType.PRESTASHOP),
			status: null,
			// connectLink:
			// 'https://valerian-funds.fra1.digitaloceanspaces.com/guidelines/eCommerce/Valerian-Prestashop%20Connection%20Guide.pdf',
			newTab: false,
		},
		{
			name: 'AMAZON_SELLER',
			image1: amazonImage,
			image2: amazonImageTicked,
			action: () => codatConnectionProcess(CodatConnectionType.AMAZON_SELLER),
			status: null,
			connectLink: '',
			// 'https://valerian-funds.fra1.digitaloceanspaces.com/guidelines/eCommerce/Valerian-Amazon%20Connection%20Guide.pdf',
			newTab: null,
		},
		{
			name: 'Add',
			image1: AddButton,
			image2: AddButton,
			action: () => clickAdd(ECOMMERCE_PLATFORM_ARRAY),
			status: null,
			connectLink: '',
			// 'https://valerian-funds.fra1.digitaloceanspaces.com/guidelines/eCommerce/Valerian-WooCommerce%20Connection%20Guide.pdf',
			newTab: null,
		},
	];

	const MarketingArray: MarketingAndEcommarrayProps[] = [
		{
			// beta todo
			name: 'GOOGLE_ADS',
			image1: googleAdsImage,
			image2: BetaTicked,
			connectLink: `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/adwords&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${GOOGLE_ADS_REDIRECT_URI}&client_id=${GOOGLE_ADS_CLIENT_ID}`,
			status: googleAdsBeta,
			newTab: null,
		},
		{
			name: 'GOOGLE_ANALYTICS',
			image1: googleAnalyticsImage,
			image2: googleAnalyticsTicked,
			action: clickGoogleAds,
			status: null,
		},
		{
			name: 'FACEBOOK',
			image1: facebookLogo,
			image2: facebookLogoTicked,
			action: clickFacebook,
			status: facebook,
		},
	];
	if (loading)
		return (
			<Row justify="center" align="middle" style={{ height: '100%' }}>
				<Spin
					indicator={
						<LoadingOutlined className="_spin" style={{ fontSize: 36 }} spin />
					}
				/>
			</Row>
		);

	return (
		<div>
			{/* modals */}
			<ShopifyModal
				shopifyModal={shopifyModal}
				handleCancel={handleCancel}
				onSuccess={(value: boolean) => setShopifyVisible(value)}
			/>
			<AddModal
				visible={addModal}
				handleCancel={() => setAddModal(false)}
				title={platform === ECOMMERCE_PLATFORM_ARRAY ? 'Ecommerce' : 'Payment'}
				allData={platform}
			/>
			<GoogleModal googleModal={googleModal} handleCancel={handleCancel} />
			<GoogleAnalyticsModal
				googleModal={googleAnalyticsModal}
				handleCancel={handleCancel}
			/>
			<FacebookModal googleModal={facebookModal} handleCancel={handleCancel} />
			<div>
				<h3 style={{ fontSize: '28px' }}>Connect your data platforms</h3>
				<Collapse
					accordion
					expandIconPosition={'left'}
					className={connectStyles.collapse}
					expandIcon={({ isActive }) =>
						isActive ? (
							<RightOutlined style={{ color: '#ed572f' }} />
						) : (
							<DownOutlined />
						)
					}
				>
					<Panel
						header="How we treat your data"
						key="1"
						className={connectStyles.collapseHeader}
					>
						<p className={connectStyles.customParagraph}>{text}</p>
					</Panel>
				</Collapse>
				<br />
				<div>
					<div style={{ width: '100%' }}>
						<ConnectPlatFormCard
							platforms={PaymentsArray}
							name={'Payment Platforms:'}
						/>
					</div>
					<br />
					<div style={{ width: '100%' }}>
						<ConnectPlatFormModal
							platforms={EcommArray}
							name={'Ecommerce Platforms:'}
						/>
					</div>
					<br />
					<ConnectPlatFormModal
						platforms={MarketingArray}
						name={'Marketing Platforms:'}
					/>
				</div>
				<OtherChoiceModal
					otherChoiceModal={otherChoiceModal}
					otherChoiceModalCancel={handleCancel}
				/>
				<Row className={connectStyles.spanText}>
					<span>
						Do you use any data platforms not listed above?{' '}
						<a onClick={showOtherChoiceModal}> Click here</a> to let us know what you
						use.
					</span>
				</Row>
				<Row>
					<Button
						type="primary"
						className="_form_submit_small onboarding_connect_cards_next_button"
						onClick={() => handleNext()}
					>
						Next
					</Button>
				</Row>
			</div>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	stripe: state.auth.loginData.businessPlatform.STRIPE,
	shopify: state.auth.loginData.businessPlatform.SHOPIFY,
	facebook: state.auth.loginData.marketingPlatform.FACEBOOK,
	googleAdsBeta: state.auth.loginData.marketingPlatform.GOOGLE_ADS,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (disptach: any) => ({
	updateClientCurrentState: (currentState: string, force: boolean) => {
		return disptach(updateClientCurrentState(currentState, force));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectCard);
