/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row, Image } from 'antd';
import { PaymentarrayProps } from 'components/dto/dto';
import React from 'react';
import ConnectItem from './ConnectItem';

interface Props {
	// image1: any;
	// image2: any;
	// connectLink1: string | undefined;
	// connectLink2: string | undefined;
	// image1Done: any;
	// status1: boolean;

	platforms: PaymentarrayProps[];
	name?: string;
}

const ConnectPlatFormCard: React.FC<Props> = ({
	// image1,
	// image2,
	// connectLink1,
	// status1,
	// image1Done,
	name,
	platforms,
}) => {
	return (
		<>
			<b>{name}</b>
			<Col span={20}>
				<Row
					justify="space-between"
					style={{
						padding: '2rem',
					}}
				>
					{platforms &&
						platforms.map((platform: PaymentarrayProps, i: number) => (
							<Col
							// span={3}
							// style={{
							// 	margin: '0rem 0.75rem',
							// 	display: 'flex',
							// 	alignItems: 'center',
							// 	justifyContent: 'center',
							// }}
							>
								{/* <a
								href={platform.connectLink}
								target={platform.newTab ? '_blank' : '_self'}
							>
								<Image
									src={platform.status ? platform.image2 : platform.image1}
									alt={platform.image1}
									preview={false}
								/>
							</a> */}
								<ConnectItem
									name={platform.name}
									connectionLink={platform.connectLink}
									img={platform.image1}
									action={platform?.action}
									newTab={platform?.newTab}
								/>
							</Col>
						))}
					{/* <Row>
				<Col>
					<a href={connectLink1}>
						<Image src={status1 ? image1Done : image1} alt={image1} preview={false} />
					</a>
				</Col>
				<Col offset={1}>
					<Image src={image2} alt={image2} preview={false} />
				</Col>
			</Row> */}
				</Row>
			</Col>
		</>
	);
};

export default ConnectPlatFormCard;
