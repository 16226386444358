import React from 'react';
import { Modal } from 'antd';

interface Props {
	googleModal: boolean;
	handleCancel: () => void;
}

const FacebookModal: React.FC<Props> = ({ googleModal, handleCancel }) => {
	return (
		<Modal
			title="Facebook Connect"
			visible={googleModal}
			onCancel={handleCancel}
			footer={false}
		>
			<p style={{ fontSize: '18px' }}>
				<strong>Connection to Facebook Ads:</strong>
			</p>
			<p>
				In order for us to get a full understanding of your business, we need to
				have access to your marketing data. We therefore ask that you grant us View
				Performance access to your Facebook Ad Accounts. We require read-only access
				to your data, and you will be able to revoke our access at any time.
			</p>
			<br />
			<p>
				<a href="https://business.facebook.com/settings">
					https://business.facebook.com/settings
				</a>
			</p>
			<ol>
				<li>
					<p>Below Users, select Partners (left menu)</p>
				</li>
				<li>
					<p>
						Click “Add” under “Partner to share assets with” or “Give a partner access
						to your assets”
					</p>
				</li>
				<li>
					<p>
						Enter the Valerian Partner Business ID: “613733646306471” and then click
						Next
					</p>
				</li>

				<li>
					<p>
						Select “Ad Accounts” in left menu and all relevant Ad Account assets in
						middle
					</p>
				</li>

				<li>
					<p>
						In right hand pane, unselect all options, and select only “View
						performance”
					</p>
				</li>

				<li>
					<p>Click “Save Changes”</p>
				</li>
			</ol>
		</Modal>
	);
};

export default FacebookModal;
