import { Modal } from 'antd';
import React from 'react';

interface Props {
	googleModal: boolean;
	handleCancel: () => void;
}

const GoogleModal: React.FC<Props> = ({ googleModal, handleCancel }) => {
	return (
		<Modal
			title="Google Ads"
			visible={googleModal}
			onCancel={handleCancel}
			footer={false}
		>
			<p style={{ fontSize: '18px' }}>
				<strong>Connection to Google Ads:</strong>
			</p>
			<p>
				In order for us to get a full understanding of your business, we need to
				have access to your marketing data. We therefore ask that you add{' '}
				<a href="data17@valerianfunds.com">data17@valerianfunds.com</a> as a user
				within your Google Ads account. We require read-only access to your data,
				and you will be able to revoke our access at any time.
			</p>
			<br />
			<p>
				{/* 	href="https://valerian-funds.fra1.digitaloceanspaces.com/guidelines/Marketing/Valerian-Google%20Ads%20Connection%20Guide.pdf"
					target="_blank" */}
				<a href="https://ads.google.com/aw/accountaccess/settings">
					https://ads.google.com/aw/accountaccess/settings
				</a>
			</p>
			<ol>
				<li>
					<p>Select “Add Domain”</p>
				</li>
				<li>
					<p>
						Enter <strong>valerianfunds.com</strong> and click “Save”
					</p>
				</li>
				<li>
					<p>In the top menu select “Users”</p>
				</li>
				<li>
					<p>
						Click + sign in the top left corner and enter{' '}
						<strong>data17@valerianfunds.com</strong>
					</p>
				</li>
				<li>
					<p>Select Account Access Level to be: “read only”</p>
				</li>
				<li>
					<p>Click Save.</p>
				</li>
			</ol>
		</Modal>
	);
};

export default GoogleModal;
