/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Col, Form, Input, InputNumber, Row, Select } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
	onChangeBusinessInfo,
	onChangeCountry,
} from 'store/actions/authAction';
import { countryList } from './countryList';

interface Props {
	changeBusinessInfo: (data: any) => void;
	changeCountry: (name: string) => void;
	submitBusiness: () => void;
	goPrevious: () => void;
	authBusiness: any;
}

const EligibilityForm: React.FC<Props> = ({
	changeBusinessInfo,
	changeCountry,
	submitBusiness,
	authBusiness,
	goPrevious,
}) => {
	const [form] = Form.useForm();
	const { Option } = Select;

	const businessType = ['Ecommerce', 'Subscription', 'Marketplace', 'Other'];

	useEffect(() => {
		form.setFieldsValue({
			currency: authBusiness.currency,
		});
	}, [authBusiness]);

	const onFinish = () => {
		submitBusiness();
	};

	const handleDigitValueWithKey = (key: any) => (e: any) => {
		const val = e.target.value;
		const digitValue = val && val.replace(/^0+/, '').replace(/[^\d]+/g, '');
		form.setFieldsValue({
			[key]: `${digitValue}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
		});
		changeBusinessInfo(e);
	};

	return (
		<>
			<h3>Determine eligibility</h3>

			<Form
				id="Registration-Form-2"
				layout="vertical"
				requiredMark={false}
				onFinish={onFinish}
				form={form}
				onChange={(e) => changeBusinessInfo(e)}
				initialValues={{
					companyName: authBusiness.companyName,
					regCountry: authBusiness.regCountry,
					website: authBusiness.website,
					businessType: authBusiness.businessType,
					revMonth: parseFloat(authBusiness.revMonth.replace(/,/g, ''))
						.toString()
						.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
					reqAdvanceAmount: parseFloat(
						authBusiness.reqAdvanceAmount.replace(/,/g, '')
					)
						.toString()
						.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
					currency: authBusiness.currency,
				}}
				style={{
					textAlign: 'start',
					paddingTop: '1.6rem',
				}}
			>
				<Form.Item
					label="Company Name"
					name="companyName"
					rules={[
						{
							required: true,
							message: 'Please input your company name',
						},
					]}
				>
					<Input name="companyName" onChange={(e) => changeBusinessInfo(e)} />
				</Form.Item>

				<Form.Item
					label="Country of Registration"
					name="regCountry"
					rules={[
						{
							required: true,
							message: 'Please select your country',
						},
					]}
				>
					<Select
						onChange={(name: string) => {
							changeCountry(name);
						}}
						placeholder="Country of Registration"
					>
						{countryList.map((data) => (
							<Option value={data.country} key={data.country}>
								{data.country}
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					label="Website"
					name="website"
					rules={[
						{
							required: true,
							message: 'Please input your website url',
						},
					]}
				>
					<Input name="website" onChange={(e) => changeBusinessInfo(e)} />
				</Form.Item>

				<Form.Item
					label={`Type of business`}
					name="businessType"
					rules={[
						{
							required: true,
							message: 'Please input your business type',
						},
					]}
				>
					<Select
						onChange={(e) => {
							let event = {
								target: {
									name: 'businessType',
									value: e,
								},
							};
							changeBusinessInfo(event);
						}}
						placeholder="Type of business"
					>
						{businessType.map((type: string) => {
							return (
								<Option name="businessType" value={type} key={type}>
									{type}
								</Option>
							);
						})}
					</Select>
				</Form.Item>

				<Form.Item label="Approximate monthly revenue">
					<Input.Group compact>
						<Form.Item name="currency" noStyle>
							<Input style={{ width: '20%' }} name="currency" disabled />
						</Form.Item>

						<Form.Item
							name="revMonth"
							noStyle
							rules={[
								{
									required: true,
									message: 'Please input your approximate monthly revenue',
								},
								// ({ getFieldValue }) => ({
								// 	validator(_, value) {
								// 		if (value && getFieldValue('revMonth') !== '0') {
								// 			return Promise.resolve();
								// 		}
								// 		return Promise.reject(
								// 			new Error('Approximate monthly revenue should be > 0')
								// 		);
								// 	},
								// }),
							]}
						>
							<Input
								style={{ width: '80%' }}
								name="revMonth"
								placeholder="500,000"
								maxLength={10}
								onChange={handleDigitValueWithKey('revMonth')}
							/>
						</Form.Item>
					</Input.Group>
				</Form.Item>

				<Form.Item label="Requested advance amount ">
					<Input.Group compact>
						<Form.Item name="currency" noStyle>
							<Input style={{ width: '20%' }} name="currency" disabled />
						</Form.Item>

						<Form.Item
							name="reqAdvanceAmount"
							noStyle
							rules={[
								{
									required: true,
									message: 'Please input your requested advance amount',
								},
								// ({ getFieldValue }) => ({
								// 	validator(_, value) {
								// 		if (value && getFieldValue('reqAdvanceAmount') !== '0') {
								// 			return Promise.resolve();
								// 		}
								// 		return Promise.reject(
								// 			new Error('Requested advance amount should be > 0')
								// 		);
								// 	},
								// }),
							]}
						>
							<Input
								style={{ width: '80%' }}
								name="reqAdvanceAmount"
								placeholder="500,000"
								maxLength={10}
								onChange={handleDigitValueWithKey('reqAdvanceAmount')}
							/>
						</Form.Item>
					</Input.Group>
				</Form.Item>

				<Form.Item>
					<Row>
						<Col
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Button
								className="_previous_small"
								type="primary"
								onClick={() => goPrevious()}
							>
								Previous
							</Button>
						</Col>
						<Col>
							<Button className="_form_submit_small" type="primary" htmlType="submit">
								Next
							</Button>
						</Col>
					</Row>
				</Form.Item>
			</Form>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	authBusiness: state.auth.businessInfo,
});

const mapDispatchToProps = (dispatch: any) => ({
	changeBusinessInfo: (data: any) => {
		dispatch(onChangeBusinessInfo(data));
	},
	changeCountry: (country: string) => {
		dispatch(onChangeCountry(country));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(EligibilityForm);
