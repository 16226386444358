import React, { useEffect, useState } from 'react';
import { Button, message, Row, Collapse, Select, Modal, Col } from 'antd';
import ChooseOffer from '../OfferSteps/ChooseOffer';
import Repayment from '../OfferSteps/Repayment';
import SignContract from '../OfferSteps/SignContract';
import axios from 'axios';
import { BASE_URL } from 'config';
import { connect } from 'react-redux';
import {
	checkLoginData,
	offer_selected,
	updateValue,
} from 'store/actions/authAction';
import { updateForceState } from 'services/dashboard';
import {
	setRepaymentGatewayString,
	set_offer_selected_done,
	set_selected_offer,
} from 'store/actions/dashBoardActions';
import { RootState } from 'dto/redux.action.dto';
import connectStyles from 'design/scss/connect.module.scss';
import {
	DownOutlined,
	ExclamationCircleOutlined,
	RightOutlined,
} from '@ant-design/icons';

interface OfferPlatformProps {
	accessToken: string;
	loginData: any;
	offer_selected: (offerId: string) => void;
	offerId: string;
	set_selected_offer: (offerId: string) => void;
	dash: any;
	currentState: string;
	set_offer_selected_done: () => void;
	forceStates: Array<string>;
	checkLoginData: () => void;
	updateValue: (key: string, value: number) => void;
	repaymentGateway: any[];
}

const { Panel } = Collapse;
const text = `
We understand the value of your data. That is why we use your information
to provide a bespoke Offer that meets the specific needs of your business.
Valerian is fully compliant with EU GDPR and UK DPPEC regulations, and you
will always have the option to remove our access and ask us to delete your
data. For further information please see our privacy policy.
`;
const { Option } = Select;
const OfferCard: React.FC<OfferPlatformProps> = ({
	accessToken,
	offer_selected,
	offerId,
	set_selected_offer,
	dash,
	currentState,
	set_offer_selected_done,
	forceStates,
	checkLoginData,
	updateValue,
	repaymentGateway,
}) => {
	const [current, setCurrent] = useState<number>(0);
	const [disableNext, setDisableNext] = useState<boolean>(true);
	const [disableBankDetailsButton, setDisableBankDetailsButton] =
		useState<boolean>(false);
	const [gocardlessUrl, setGocardlessUrl] = useState<string>('');
	const { offer } = dash;

	const { confirm } = Modal;

	const submitOffer = async (offer: any) => {
		try {
			const data = {
				...offer,
				currency: offer.currency,
				initialBalance: offer.initialBalance / 100,
				fee: offer.fee / 100,
			};
			const advance = await axios.post(`${BASE_URL}advance`, data, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					'Content-Type': 'application/json',
				},
			});

			if (advance) {
				if (forceStates.length > 0 && forceStates[0] === 'OFFER') {
					await updateForceState('OFFER');
					checkLoginData();
				} else {
					setCurrent(2);
					set_offer_selected_done();
					updateValue('onBoardingStep', 6);
				}
			}
		} catch (err) {
			message.error('Could not submit offer');
		}
	};

	useEffect(() => {
		if (forceStates.length > 0) {
			if (forceStates[0] === 'OFFER') {
				setCurrent(0);
			} else if (forceStates[0] === 'GOCARDLESS') {
				setCurrent(2);
			}
		} else {
			if (currentState === 'GOCARDLESS') {
				setCurrent(2);
			}
		}
		repaymentGateway && repaymentGateway.length > 1
			? setDisableBankDetailsButton(true)
			: setDisableBankDetailsButton(false);
	}, [currentState, forceStates]);

	const steps = [
		{
			title: 'Select offer',
			content: (
				<ChooseOffer
					submitOffer={submitOffer}
					chooseOffer={(value: any) => handleSelect(value)}
				/>
			),
		},
		{
			title: 'Sign contract',
			content: (
				<SignContract
					offerId={offerId}
					offer={offer}
					nextBtn={(value: boolean) => setDisableNext(value)}
				/>
			),
		},
		{
			title: 'Setup repayments',
			content: <Repayment setUrl={(value: string) => setGocardlessUrl(value)} />,
		},
	];

	const handleSelect = async (data: any) => {
		set_selected_offer(data);
		offer_selected(data.offerId);
		next();
		updateValue('onBoardingStep', 5);
	};

	const next = async () => {
		if (current === 1) {
			submitOffer(offer);
		} else {
			setCurrent(current + 1);
		}
	};

	const prev = () => {
		setCurrent(current - 1);
		updateValue('onBoardingStep', 4);
	};

	const getGoCardlessUrl = async (repaymentGateway: string) => {
		const options = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		};

		const body = {
			repaymentGateway,
		};

		await axios
			.post(`${BASE_URL}connect/gocardless/getLink`, body, options)
			.then((res: any) => {
				setGocardlessUrl(res.data.url);
				setDisableBankDetailsButton(false);
			});
	};

	const handleSelectChange = (index: number) => {
		setDisableBankDetailsButton(true);
		const advance = repaymentGateway[index];
		setRepaymentGatewayString(advance.repaymentGateway, advance.advanceId);
		getGoCardlessUrl(advance.repaymentGateway);
	};
	// const showConfirm = (offerBody: any) => {
	// 	confirm({
	// 		title: 'Did you sign the contract?',
	// 		icon: <ExclamationCircleOutlined />,
	// 		okText: 'Yes',
	// 		cancelText: 'No',
	// 		onOk() {
	// 			// submitOffer({
	// 			// 	...offer,
	// 			// 	currency: offer.currency,
	// 			// 	initialBalance: offer.initialBalance / 100,
	// 			// 	fee: offer.fee / 100,
	// 			// });
	// 			next();
	// 		},
	// 	});
	// };

	return (
		<div>
			<h3 style={{ fontSize: '28px' }}>
				{current === 0
					? 'Select Offer'
					: current === 1
					? 'Sign contract'
					: current === 2
					? 'Set up repayments'
					: ''}
			</h3>
			<Collapse
				accordion
				expandIconPosition={'left'}
				className={connectStyles.collapse}
				expandIcon={({ isActive }) =>
					isActive ? (
						<RightOutlined style={{ color: '#ed572f' }} />
					) : (
						<DownOutlined />
					)
				}
			>
				<Panel
					header="How we treat your data"
					key="1"
					className={connectStyles.collapseHeader}
				>
					<p className={connectStyles.customParagraph}>{text}</p>
				</Panel>
			</Collapse>
			<div style={{ background: 'none' }}>{steps[current].content}</div>
			<Row justify="start">
				{current === 1 && (
					<Col md={12} lg={8} xs={24}>
						<Button
							className="noColor _form_submit_small"
							onClick={() => prev()}
							type="primary"
						>
							Previous
						</Button>
					</Col>
				)}

				{current > 0 && current !== steps.length - 1 && (
					<Col md={12} lg={8} xs={24}>
						<Button
							type="primary"
							className="_form_submit_small _sp_Margin_left"
							onClick={() => next()}
							disabled={disableNext}
						>
							Next
						</Button>
					</Col>
				)}

				{current === steps.length - 1 && (
					<>
						{repaymentGateway && repaymentGateway.length > 1 && (
							<Row className="_full_width">
								<Select
									placeholder="Select Repayment Gateway"
									className="_full_width"
									onChange={handleSelectChange}
								>
									{repaymentGateway.map((value: any, index: number) => (
										<Option value={index}>
											{`${value.advanceId} - Amount ${value.initialBalance}`}
										</Option>
									))}
								</Select>
							</Row>
						)}
						<br />
						<br />
						<a href={gocardlessUrl} target="_blank">
							<Button
								type="primary"
								className="_next_step ml-0 provideBankDetailsBtn"
								disabled={gocardlessUrl === null || undefined}
								loading={gocardlessUrl === null || undefined}
							>
								{gocardlessUrl === null || undefined ? '' : 'Provide Bank Details'}
							</Button>
						</a>
					</>
				)}
			</Row>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	accessToken: state.auth.loginData.accessToken,
	loginData: state.auth.loginData,
	offerId: state.auth.loginData.offerId,
	dash: state.dash,
	currentState: state.auth.loginData.currentState,
	forceStates: state.auth.loginData.forceStates,
	repaymentGateway: state.dash.repaymentGateway,
});

const mapDispatchToProps = (dispatch: any) => ({
	offer_selected: (offerId: string) => {
		dispatch(offer_selected(offerId));
	},
	set_selected_offer: (offer: any) => dispatch(set_selected_offer(offer)),
	set_offer_selected_done: () => dispatch(set_offer_selected_done()),
	checkLoginData: () => dispatch(checkLoginData()),
	updateValue: (key: string, value: number) => {
		dispatch(updateValue(key, value));
	},
	setRepaymentGatewayString: (
		repaymentGatewayString: string,
		advanceId: string
	) => {
		dispatch(setRepaymentGatewayString(repaymentGatewayString, advanceId));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferCard);
