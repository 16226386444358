import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import UploadFinancial from '../Financial/UploadFinancial';
import KYCForm from '../Financial/KYCForm';
import { connect } from 'react-redux';
import {
	checkLoginData,
	set_document_submitted,
} from 'store/actions/authAction';
import { getClient, updateForceState } from 'services/dashboard';
import { RootState } from 'dto/redux.action.dto';
import connectStyles from 'design/scss/connect.module.scss';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

interface Props {
	set_document_submitted: (newState: string) => void;
	checkLoginData: () => void;
	documentSubmitted: boolean | null;
	kycSubmitted: boolean | null;
	currentState: string | null;
	forceStates: Array<string>;
}

const { Panel } = Collapse;
const text = `
We understand the value of your data. That is why we use your information
to provide a bespoke Offer that meets the specific needs of your business.
Valerian is fully compliant with EU GDPR and UK DPPEC regulations, and you
will always have the option to remove our access and ask us to delete your
data. For further information please see our privacy policy.
`;

const FinancialsCard: React.FC<Props> = ({
	set_document_submitted,
	currentState,
	forceStates,
	checkLoginData,
}) => {
	const [step, setStep] = useState(1);
	const [kyc, setKYC] = useState(null);

	const submitUploadFinancial = async () => {
		if (forceStates.length > 0 && forceStates[0] === 'FINANCIAL') {
			await updateForceState('FINANCIAL');
			checkLoginData();
		} else {
			set_document_submitted('KYC');
			setStep(2);
		}
	};

	useEffect(() => {
		if (forceStates.length > 0) {
			if (forceStates[0] === 'FINANCIAL') {
				setStep(1);
			} else if (forceStates[0] === 'KYC') {
				setStep(2);
			}
		} else {
			if (currentState === 'FINANCIAL') {
				setStep(1);
			}
			if (currentState === 'KYC') {
				setStep(2);
			}
		}
	}, [currentState]);

	useEffect(() => {
		if (forceStates.length > 0) {
			getClinetInfo();
		}
	}, []);

	const getClinetInfo = async () => {
		const res = await getClient();
		if (res?.data?.kyc) {
			setKYC(res.data.kyc);
		}
	};

	return (
		<div>
			<h3 style={{ fontSize: '28px' }}>
				{step === 1 ? 'Provide financial data' : 'Verify identity'}
			</h3>
			<Collapse
				accordion
				expandIconPosition={'left'}
				className={connectStyles.collapse}
				expandIcon={({ isActive }) =>
					isActive ? (
						<RightOutlined style={{ color: '#ed572f' }} />
					) : (
						<DownOutlined />
					)
				}
			>
				<Panel
					header="How we treat your data"
					key="1"
					className={connectStyles.collapseHeader}
				>
					<p className={connectStyles.customParagraph}>{text}</p>
				</Panel>
			</Collapse>
			<br />
			{step === 1 ? (
				<UploadFinancial
					fileType="businessInfo"
					submitForm={submitUploadFinancial}
				/>
			) : (
				<KYCForm kyc={kyc} />
			)}
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	documentSubmitted: state.auth.loginData.documentSubmitted,
	kycSubmitted: state.auth.loginData.kycSubmitted,
	currentState: state.auth.loginData.currentState,
	forceStates: state.auth.loginData.forceStates,
});

const mapPropsToState = (dispatch: any) => ({
	set_document_submitted: (newState: string) =>
		dispatch(set_document_submitted(newState)),
	checkLoginData: () => dispatch(checkLoginData()),
});

export default connect(mapStateToProps, mapPropsToState)(FinancialsCard);
