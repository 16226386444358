import React from 'react';
import { message, Steps } from 'antd';
import connectStyles from 'design/scss/connect.module.scss';

import { connect } from 'react-redux';
import { updateClientCurrentState } from 'store/actions/authAction';

import { setCheckFinancial } from 'store/actions/dashBoardActions';
import { setCheckKYC } from 'store/actions/dashBoardActions';

const { Step } = Steps;

interface StepperProps {
	current: number;
	stepArray: string[];
	updateClientCurrentState: (state: string, force: boolean) => void;
	checked: boolean;
	setChecked: (checked: boolean) => void;
	kycchecked: boolean;
	setkycChecked: (checked: boolean) => void;
}
const Stepper: React.FC<StepperProps> = ({
	current,
	stepArray,
	updateClientCurrentState,
	checked,
	setChecked,
	kycchecked,
	setkycChecked,
}) => {
	const handleChange = async (e: any) => {
		switch (e) {
			case 1:
				await updateClientCurrentState('CONNECT_PLATFORMS', true);
				break;
			case 2:
				await updateClientCurrentState('FINANCIAL', true);
				break;
			case 3:
				{
					checked
						? await updateClientCurrentState('KYC', true)
						: message.error('Please Complete the form!');
				}
				break;
			case 4:
				{
					kycchecked
						? await updateClientCurrentState('CONNECTION_DONE', false)
						: message.error('Please Complete the form!');
				}
				break;
			default:
				null;
				break;
		}
	};

	return (
		<>
			{stepArray.length > 1 && (
				<div className={connectStyles.stepper}>
					<Steps
						className="custome-step responsiveStepperHorizontal"
						current={current}
						direction="horizontal"
						onChange={handleChange}
					>
						{stepArray &&
							stepArray.map((step: string, i: number) => (
								<Step
									title={step}
									key={i}
									// disabled={step.key <= current && step.key !== 0 ? false : true}
									disabled={i <= current && i !== 0 ? false : true}
								/>
							))}
					</Steps>
					<Steps
						className="custome-step responsiveStepperVertical"
						current={current}
						direction="vertical"
						onChange={handleChange}
					>
						{stepArray &&
							stepArray.map((step: string, i: number) => (
								<Step
									title={step}
									key={i}
									// disabled={step.key <= current && step.key !== 0 ? false : true}
									disabled={i <= current && i !== 0 ? false : true}
								/>
							))}
					</Steps>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state: any) => ({
	checked: state.dash.checkFinancial,
	kycchecked: state.dash.checkKYC,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapDispatchToProps = (dispatch: any) => ({
	updateClientCurrentState: (currentState: string, force: boolean) => {
		return dispatch(updateClientCurrentState(currentState, force));
	},

	setChecked: (checked: boolean) => {
		return dispatch(setCheckFinancial(checked));
	},

	setkycChecked: (checked: boolean) => {
		return dispatch(setCheckKYC(checked));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(Stepper);
