import { Button, Form, Input, message, Modal, Row } from 'antd';
import axios from 'axios';
import { BASE_URL } from 'config';
import { RootState } from 'dto/redux.action.dto';
import React, { useState } from 'react';
import { connect } from 'react-redux';

interface Props {
	otherChoiceModal: boolean;
	otherChoiceModalCancel: () => void;
	accessToken: string | null;
}

const OtherChoiceModal: React.FC<Props> = ({
	otherChoiceModal,
	otherChoiceModalCancel,
	accessToken,
}) => {
	const handleSubmit = async (values: any) => {
		const dataText = values.other_choice;

		const data = {
			type: 'more_data_connection',
			text: dataText,
		};

		const options = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		};

		try {
			const res = await axios.post(
				`${BASE_URL}client/custom-email`,
				data,
				options
			);
			if (res.status === 200) {
				message.success('Successfully submitted your choice of platform');

				otherChoiceModalCancel();
			}
		} catch (err) {
			message.error(`Couldn't submit your choice of platform`);
		}
	};

	return (
		<Modal
			title="Please provide details of what data platforms you use to manage your business:"
			visible={otherChoiceModal}
			footer={false}
			onCancel={otherChoiceModalCancel}
			width={500}
		>
			<Form onFinish={handleSubmit} layout="vertical" id="Other-Choice-Form">
				<Form.Item name="other_choice">
					<Input placeholder="For example - I use Klarna for payments" />
				</Form.Item>
				<Row justify="center">
					<Button type="primary" htmlType="submit" className="_form_submit_small">
						Submit
					</Button>
				</Row>
			</Form>
		</Modal>
	);
};

// export default OtherChoiceModal;

const mapStateToProps = (state: RootState) => ({
	accessToken: state.auth.loginData.accessToken,
});

export default connect(mapStateToProps)(OtherChoiceModal);
