/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Button, Form, Input, message, Modal, Row, Spin } from 'antd';
import axios from 'axios';
import { BASE_URL } from 'config';
import { connect } from 'react-redux';
import { updateForceState } from 'services/dashboard';
import { checkLoginData, handleShopifyConnect } from 'store/actions/authAction';
import { RootState } from 'dto/redux.action.dto';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
	shopifyModal: boolean;
	handleCancel: () => void;
	accessToken: string | null;
	handleShopifyConnect: () => void;
	forceStates: Array<string>;
	checkLoginData: () => void;
	onSuccess?: (shopifyModal: boolean) => void;
}

const antIcon = (
	<LoadingOutlined style={{ fontSize: 24, color: 'white' }} spin />
);

const ShopifyModal: React.FC<Props> = ({
	shopifyModal,
	handleCancel,
	accessToken,
	handleShopifyConnect,
	forceStates,
	checkLoginData,
	onSuccess,
}) => {
	const [btnLoading, setBtnLoading] = useState<boolean>(false);

	const [form] = Form.useForm();

	const handleSubmit = async (values: any) => {
		setBtnLoading(true);
		const storeUrl = values.store_url;
		const store_name = storeUrl.replace(/^https?:\/\//, '').split('.', 1);
		let body;

		const httpsCheck = storeUrl.split(':', 1);

		if (httpsCheck[0] === 'https') {
			const dotComCheck = storeUrl.split('.', 3);
			if (dotComCheck[2] === 'com' || dotComCheck[2] === 'com/') {
				body = {
					...values,
					store_url: `${storeUrl}`,
				};
			} else {
				body = {
					...values,
					store_url: `${storeUrl}.myshopify.com/`,
				};
			}
		} else {
			const dotComCheck = storeUrl.split('.', 3);
			if (dotComCheck[2] === 'com' || dotComCheck[2] === 'com/') {
				body = {
					...values,
					store_url: `https://${storeUrl}`,
				};
			} else {
				body = {
					...values,
					store_url: `https://${storeUrl}.myshopify.com/`,
				};
			}
		}
		const options = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		};

		try {
			const Data = { ...body, store_name: store_name[0] };
			const { data } = await axios.post(
				`${BASE_URL}connect/shopify`,
				Data,
				options
			);
			if (data.status === 201 || data.status === 200) {
				setBtnLoading(false);
				handleShopifyConnect();
				checkLoginData();
				if (forceStates.length > 0 && forceStates[0] === 'SHOPIFY') {
					await updateForceState('SHOPIFY');
					checkLoginData();
				}
				message.success(
					'Congratulations you have succesfully connected your Shopify Account'
				);
				onSuccess(false);
				form.resetFields();
			}
		} catch (err) {
			setBtnLoading(false);
			if (err.response?.status === 409) {
				message.error(`This Shopify store is already associated with an account.`);
			} else {
				message.error(err.response.data.message);
			}
		}
	};

	return (
		<Modal
			title="Shopify"
			visible={shopifyModal}
			onCancel={handleCancel}
			footer={false}
		>
			<h4 style={{ fontSize: '20px' }}>
				Detailed instructions for connecting your Shopify account{' '}
				{/* <a target="_blank" href="https://valerianfunds.com/connection-to-shopify/">
					available here
				</a>{' '} */}
				{/* or{' '} */}
				<a
					target="_blank"
					href="https://valerian-funds.fra1.digitaloceanspaces.com/guidelines/eCommerce/Valerian-Shopify%20Connection%20Guide.pdf"
				>
					available here
				</a>
			</h4>
			<p>
				Once these steps are completed, please provide the following information,
				found under API credentials:
			</p>

			<Form
				id="Shopify-Form"
				onFinish={handleSubmit}
				layout="vertical"
				form={form}
			>
				<Form.Item
					label="Store Name"
					name="store_url"
					className="spaceFont"
					rules={[
						{
							required: true,
							message: 'Please input your store name',
						},
					]}
				>
					<Input
						addonBefore="https://"
						style={{ width: '100%' }}
						addonAfter=".myshopify.com"
						placeholder="store-1"
					/>
				</Form.Item>

				<Form.Item
					label="Admin API access token"
					name="shopify_access_token"
					rules={[
						{
							required: true,
							message: 'Please input your Access Token.',
						},
					]}
				>
					<Input placeholder="shpat******" />
				</Form.Item>
				<Form.Item
					label="API Secret Key"
					name="shopify_shared_secret"
					className="spaceFont"
					rules={[
						{
							required: true,
							message: 'Please input your API secret key.',
						},
					]}
				>
					<Input placeholder="shpss_a12b2…" />
				</Form.Item>
				<Row justify="center">
					<Button
						type="primary"
						htmlType="submit"
						className="_form_submit_small"
						// loading={btnLoading}
					>
						{btnLoading ? <Spin indicator={antIcon} /> : 'Connect Shopify'}
					</Button>
				</Row>
			</Form>
		</Modal>
	);
};

const mapStateToProps = (state: RootState) => ({
	accessToken: state.auth.loginData.accessToken,
	forceStates: state.auth.loginData.forceStates,
});

const mapDisptachToProps = (dispatch: any) => ({
	handleShopifyConnect: () => dispatch(handleShopifyConnect()),
	checkLoginData: () => dispatch(checkLoginData()),
});

export default connect(mapStateToProps, mapDisptachToProps)(ShopifyModal);
