/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, message, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { StepHeader } from 'components';
import axios from 'axios';
import { BASE_URL } from 'config';
import { connect } from 'react-redux';
import offerStyles from '../../design/scss/allOfferComponents.module.scss';
import getSymbolFromCurrency from 'currency-symbol-map';
import { numberWithCommas } from 'services/utils';

interface ChooseOfferProps {
	chooseOffer: (value: any) => void;
	clientId?: string;
	accessToken?: string;
	currency?: string | undefined;
	submitOffer?: (body: any) => void;
}

const ChooseOffer: React.FC<ChooseOfferProps> = (props: ChooseOfferProps) => {
	const [offers, setOffers] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const loadOffer = async () => {
		setLoading(true);
		try {
			const res = await axios.get(`${BASE_URL}offer/valid-offer`, {
				headers: {
					Authorization: `Bearer ${props.accessToken}`,
					'Content-Type': 'application/json',
				},
			});

			setOffers(res.data);

			setLoading(false);
		} catch (err) {
			message.error('No offers found');
			setLoading(false);
		}
	};

	useEffect(() => {
		loadOffer();
	}, []);
	// const showConfirm = (offerBody: any) => {
	// 	confirm({
	// 		title: 'Did you sign the contract?',
	// 		icon: <ExclamationCircleOutlined />,
	// 		okText: 'Yes',
	// 		cancelText: 'No',
	// 		onOk() {
	// 			props.submitOffer({
	// 				initialBalance: offerBody.offer.amount,
	// 				repaymentRate: offerBody.offer.repaymentRate,
	// 				fee: offerBody.offer.fee,
	// 				clientId: props.clientId,
	// 				offerId: offerBody.offerId,
	// 				currency: offerBody.offer.currency,
	// 				pandaDocClientLink: offerBody.pandaDocClientLink,
	// 			});
	// 		},
	// 		onCancel() {
	// 			props.chooseOffer({
	// 				initialBalance: offerBody.offer.amount,
	// 				repaymentRate: offerBody.offer.repaymentRate,
	// 				fee: offerBody.offer.fee,
	// 				clientId: props.clientId,
	// 				offerId: offerBody.offerId,
	// 				currency: offerBody.offer.currency,
	// 				pandaDocClientLink: offerBody.pandaDocClientLink,
	// 			});
	// 		},
	// 	});
	// };

	return (
		<React.Fragment>
			<Row>
				<StepHeader description="Our algorithm has defined different options for your Valerian Advance. Please select your preferred offer." />
			</Row>
			<Row>
				{loading ? (
					<Spin indicator={<LoadingOutlined className="_spin" spin />} />
				) : offers.length > 0 ? (
					offers.map((offerBody: any, index: number) => (
						<div className={offerStyles.offerCards} key={index}>
							<Card
								title={
									<h5 className={offerStyles.offerName}>{offerBody.offer.name}</h5>
								}
								style={{
									width: '100%',
									borderRadius: '16px',
									textAlign: 'center',
								}}
							>
								<p className={offerStyles.offerBodyDescription}>
									{offerBody.offer.description}
								</p>
								<Row>
									<Col span={24}>
										<p className={offerStyles.eligibilityheader}>Eligible Amount</p>
									</Col>
									<Col span={24}>
										<p className={offerStyles.eligibilityContent}>
											<strong>
												{getSymbolFromCurrency(offerBody?.offer?.currency)}{' '}
												{numberWithCommas(offerBody.offer.amount / 100)}
											</strong>
										</p>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<p className={offerStyles.eligibilityheader}>Fee</p>
									</Col>
									<Col span={24}>
										<p className={offerStyles.eligibilityContent}>
											<strong>
												{(
													(parseFloat(offerBody.offer.fee) /
														parseFloat(offerBody.offer.amount)) *
													100
												).toFixed(2)}
												%
											</strong>
										</p>
									</Col>
								</Row>
								<Row>
									<Col span={24}>
										<p className={offerStyles.eligibilityheader}>Repayment Rate </p>
									</Col>
									<Col span={24}>
										<p className={offerStyles.eligibilityContent}>
											<strong>{offerBody.offer.repaymentRate}% of revenue</strong>
										</p>
									</Col>
								</Row>

								{offerBody?.minRepayment && (
									<Row>
										<Col span={24}>
											<p className={offerStyles.eligibilityheader}>Minimum Repayment </p>
										</Col>
										<Col span={24}>
											<p className={offerStyles.eligibilityContent}>
												<strong>
													{getSymbolFromCurrency(offerBody?.offer?.currency)}{' '}
													{numberWithCommas(offerBody?.minRepayment / 100)}
												</strong>
											</p>
										</Col>
									</Row>
								)}

								<Row justify="center">
									<Button
										type="primary"
										className="_form_submit_small chooseOfferBtn"
										onClick={() =>
											props.chooseOffer({
												...offerBody,
												initialBalance: offerBody.offer.amount,
												repaymentRate: offerBody.offer.repaymentRate,
												fee: offerBody.offer.fee,
												clientId: props.clientId,
												offerId: offerBody.offerId,
												currency: offerBody.offer.currency,
												pandaDocClientLink: offerBody.pandaDocClientLink,
											})
										}
									>
										Select
									</Button>
								</Row>
							</Card>
						</div>
					))
				) : (
					<h3>No Offers Recieved</h3>
				)}
			</Row>
		</React.Fragment>
	);
};

const mapStateToProps = (state: any) => ({
	clientId: state.auth.loginData.clientId,
	accessToken: state.auth.loginData.accessToken,
});

export default connect(mapStateToProps)(ChooseOffer);
