import React, { useState } from 'react';
import {
	Row,
	Col,
	Card,
	Table,
	Space,
	Input,
	DatePicker,
	Button,
	Modal,
	Form,
} from 'antd';
import clientListStyles from '../../../design/scss/clientRepaymentsTable.module.scss';

const { Search } = Input;

const ClientRepaymentTable: React.FC = () => {
	const [isTransactionDetailsModalVisible, setIsTransactionDetailsModalVisible] =
		useState(false);
	const showTransactionModal = () => {
		setIsTransactionDetailsModalVisible(true);
	};
	const handleTransactionCancel = () => {
		setIsTransactionDetailsModalVisible(false);
	};
	const handleTransactionFormEdit = () => {
		setIsTransactionDetailsModalVisible(false);
	};

	const columns = [
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			render: (text: string) => (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{text.slice(0, 12)}</span>
					<span style={{ fontSize: '10px' }}>{text.slice(13, 24)}</span>
				</div>
			),
		},

		{
			title: 'ID',
			dataIndex: 'id',
			key: 'id',
			render: (text: string) => (
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{text.slice(0, 8)}</span>
					<span style={{ fontSize: '10px' }}>
						<b>{text.slice(9, 17)}</b> {text.slice(17, 25)}
					</span>
				</div>
			),
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
		{
			title: 'Source',
			dataIndex: 'source',
			key: 'source',
		},
		// {
		//   title: "Type",
		//   dataIndex: "type",
		//   key: "type",
		// },
		{
			title: 'Repayment Revenue',
			dataIndex: 'repayment_revenue',
			key: 'repayment_revenue',
		},
		{
			title: 'Repayment Rate',
			dataIndex: 'repayment_rate',
			key: 'repayment_rate',
		},
		{
			title: 'Repayment Amount',
			dataIndex: 'repayment_amount',
			key: 'repayment_amount',
		},
		//action
		{
			title: 'Action',
			key: 'action',
			render: () => (
				<Space size="middle">
					<a onClick={() => showTransactionModal()}>{'Details >'}</a>
				</Space>
			),
		},
	];

	const data = [];
	for (let i = 0; i < 100; i++) {
		data.push({
			key: i,
			date: `Mon 12/04/21 12:34:56 PM${i}`,
			// company_name: `Online Co. Alpha ${i}`,
			id: 'TRX-1138 Adv. ID: AD-2323',
			status: 'Scheduled',
			source: 'Stripe',
			//   type: "System",
			repayment_revenue: '25,938.02',
			repayment_rate: '13%',
			repayment_amount: '€€1,000.00',
		});
	}
	return (
		<>
			{/* transaction Details Modal */}
			<Modal
				title={
					<Row align="middle">
						<h3 style={{ margin: '16px 16px 16px 0px' }}>Transaction Details</h3>
					</Row>
				}
				visible={isTransactionDetailsModalVisible}
				onCancel={handleTransactionCancel}
				footer={null}
			>
				<Form
					name="basic"
					layout="vertical"
					requiredMark={false}
					onFinish={handleTransactionFormEdit}
				>
					<div className={clientListStyles.transactionDetailsformContent}>
						<Row>
							<Col span={10}>
								<b>ID</b>
							</Col>
							<Col span={6}>TRX-1138</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Advance ID</b>
							</Col>
							<Col span={6}>AD-2323</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Currency</b>
							</Col>
							<Col span={6}>€ (EUR)</Col>
						</Row>
						<Row align="middle">
							<Col span={10}>
								<b>Status</b>
							</Col>
							<Col span={11}>
								<div className={clientListStyles.statusButton}>
									Scheduled For Direct Debit
								</div>
							</Col>
						</Row>
						<Row align="middle">
							<Col span={10}>
								<b>Source</b>
							</Col>
							<Col span={6}>
								<div className={clientListStyles.sourceButton}>Stripe</div>
							</Col>
						</Row>
						<Row align="middle">
							<Col span={10}>
								<b>Type</b>
							</Col>
							<Col span={6}>
								<Button className={clientListStyles.ActiveButton}>System</Button>
							</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Total Sale</b>
							</Col>
							<Col span={6}>€4,212,959.42</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Total Discount</b>
							</Col>
							<Col span={6}>€12,959.42</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Total Refunds</b>
							</Col>
							<Col span={6}>€2,959.42</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Repayment Rate</b>
							</Col>
							<Col span={6}>13%</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Repayment Value</b>
							</Col>
							<Col span={6}>€1,000.00</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Repayment Revenue</b>
							</Col>
							<Col span={6}>€4,212,959.42</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Created At</b>
							</Col>
							<Col span={10}>March 26, 2021 • 02:03:04 AM</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Updated At</b>
							</Col>
							<Col span={10}>March 26, 2021 • 02:03:04 AM</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Sent To GoCardless</b>
							</Col>
							<Col span={10}>March 26, 2021 • 02:03:04 AM</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Charged At</b>
							</Col>
							<Col span={10}>March 26, 2021 • 02:03:04 AM</Col>
						</Row>
						<Row>
							<Col span={10}>
								<b>Paid Out At</b>
							</Col>
							<Col span={10}>March 26, 2021 • 02:03:04 AM</Col>
						</Row>
					</div>
				</Form>
			</Modal>

			<Card bordered={false} style={{ width: '100%' }}>
				<Row justify="space-between" align="middle">
					<h3>Repayments</h3>
					<Row className={clientListStyles.inputContainer}>
						<DatePicker.RangePicker className={clientListStyles.tableInputs} />
						<Search
							placeholder="search by name/email..."
							allowClear
							enterButton="Search"
							size="large"
							className={clientListStyles.tableInputs}
						/>
					</Row>
				</Row>
				<Table
					columns={columns}
					dataSource={data}
					pagination={{
						defaultPageSize: 5,
						showSizeChanger: true,
					}}
					style={{ overflow: 'auto' }}
				/>
			</Card>
		</>
	);
};

export default ClientRepaymentTable;
