import React from 'react';
import { Row, Tag } from 'antd';
import kycStyles from 'design/scss/kycCards.module.scss';
import moment from 'moment';
import clientListStyles from 'design/scss/clientRepaymentsTable.module.scss';
import { ClientInfo } from '../../../../../admin/src/components/ClientInfoProps/clientInfo';

interface Props {
	clientInfo: ClientInfo;
}

const CompanyInfoCard: React.FC<Props> = ({ clientInfo }) => {
	const setSourceStyle = (value: string) => {
		let color = 'default';
		if (value === 'STRIPE') {
			color = 'blue';
		} else if (value === 'SHOPIFY') {
			color = 'green';
		} else if (value === 'WOCOMMERCE') {
			color = 'orange';
		}
		return (
			<Tag color={color} key={value} className={clientListStyles.sourceButton}>
				{value}
			</Tag>
		);
	};

	return (
		<>
			<div>
				<Row className={kycStyles.infoContainer}>
					<Row justify="space-between" style={{ width: '100%' }}>
						<span className={kycStyles.infoContainer_half_title}>Company Name</span>
						<span className={kycStyles.infoContainer_half}>
							{clientInfo !== null && clientInfo.companyName}
						</span>
					</Row>
					<Row justify="space-between" style={{ width: '100%' }}>
						<span className={kycStyles.infoContainer_half_title}>Website</span>
						<span className={kycStyles.infoContainer_half}>
							{clientInfo !== null && clientInfo.website}
						</span>
					</Row>
					<Row justify="space-between" style={{ width: '100%' }}>
						<span className={kycStyles.infoContainer_half_title}>
							Country of Registration
						</span>
						<span className={kycStyles.infoContainer_half}>
							{clientInfo !== null && clientInfo.regCountry}
						</span>
					</Row>
					<Row justify="space-between" style={{ width: '100%' }}>
						<span className={kycStyles.infoContainer_half_title}>
							Business Reg. No.
						</span>
						<span className={kycStyles.infoContainer_half}>
							{' '}
							{clientInfo !== null && clientInfo.kyc.businessRegNo}
						</span>
					</Row>
					<Row justify="space-between" style={{ width: '100%' }}>
						<span className={kycStyles.infoContainer_half_title}>
							Repayment Revenue Source
						</span>
						<span className={kycStyles.infoContainer_half}>
							{/* <div className={kycStyles.registerButton} >Stripe</div> */}
							{clientInfo !== null && clientInfo.businessPlatform.defaultSource
								? setSourceStyle(clientInfo.businessPlatform.defaultSource)
								: setSourceStyle('Not selected')}
						</span>
					</Row>
					<Row justify="space-between" style={{ width: '100%' }}>
						<span className={kycStyles.infoContainer_half_title}>Onboarded on</span>
						<span className={kycStyles.infoContainer_half}>
							{clientInfo !== null && moment(clientInfo.createdAt).format('LLLL')}{' '}
						</span>
					</Row>
				</Row>
			</div>
		</>
	);
};

export default CompanyInfoCard;
