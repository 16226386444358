import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import { handleLogout } from 'store/actions/authAction';
import { clearData } from 'store/actions/dashBoardActions';
import { setDeploymentKey } from 'store/actions/settingsActions';
import { DEPLOYMENT_KEY } from 'config';
import authStyles from 'design/scss/auth.module.scss';
import { RootState } from 'dto/redux.action.dto';

interface AuthLayoutProps {
	title: string;
	isLoggedIn: boolean;
	currentState: string | null;
	forceStates: Array<string>;
	children: JSX.Element;
	deploymentKey: string | null;
	logOut: () => void;
	clearDashboard: () => void;
	setDeploymentKey: (deploymentKey: string) => void;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
	title,
	isLoggedIn,
	currentState,
	forceStates,
	children,
	deploymentKey,
	logOut,
	clearDashboard,
	setDeploymentKey,
}) => {
	const router = useRouter();

	const checkDeployment = () => {
		if (DEPLOYMENT_KEY !== deploymentKey) {
			clearDashboard();
			logOut();
			setDeploymentKey(DEPLOYMENT_KEY);
		}
	};

	useEffect(() => {
		checkDeployment();
		if (isLoggedIn) {
			if (forceStates && forceStates.length > 0) {
				router.push('/force-state');
			} else if (currentState === 'DASHBOARD') {
				router.push('/dashboard');
			} else {
				router.push('/onboarding');
			}
		}
	}, [isLoggedIn, currentState, forceStates]);

	return (
		<React.Fragment>
			<Head>
				<title>Valerian | {title}</title>
				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=UA-192095501-1"
				/>

				<script
					dangerouslySetInnerHTML={{
						__html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-192095501-1');
        `,
					}}
				/>
				<script
					dangerouslySetInnerHTML={{
						__html: `
					(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-WBJFTFX');`,
					}}
				></script>
			</Head>

			<noscript
				dangerouslySetInnerHTML={{
					__html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WBJFTFX"
						height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
				}}
			></noscript>
			<main className={authStyles.authLayout}>{children}</main>
		</React.Fragment>
	);
};

const mapStateToProps = (state: RootState) => ({
	isLoggedIn: state.auth.loginData.isLoggedIn,
	currentState: state.auth.loginData.currentState,
	forceStates: state.auth.loginData.forceStates,
	deploymentKey: state.settings.deploymentKey,
});

const mapDispatchToProps = (dispatch: any) => ({
	setDeploymentKey: (deploymentKey: string) => {
		dispatch(setDeploymentKey(deploymentKey));
	},
	logOut: () => dispatch(handleLogout()),
	clearDashboard: () => dispatch(clearData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
