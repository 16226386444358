import { Col, Modal, Row, Tag } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import clientListStyles from '../../../design/scss/clientRepaymentsTable.module.scss';
import moment from 'moment';
import { RootState } from 'dto/redux.action.dto';

interface Props {
	showModal: boolean;
	handleCancel: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: any;
	currency: string;
}

const RepaymentModal: React.FC<Props> = ({
	showModal,
	handleCancel,
	data,
	currency,
}) => {
	const setSourceStyle = (value: string) => {
		let color = 'default';
		if (value === 'STRIPE') {
			color = 'blue';
		} else if (value === 'SHOPIFY') {
			color = 'green';
		} else if (value === 'WOCOMMERCE') {
			color = 'orange';
		}
		return (
			<Tag color={color} key={value} className={clientListStyles.sourceButton}>
				{value}
			</Tag>
		);
	};

	const setStatusStyle = (value: string) => {
		let color = value.length > 5 ? 'geekblue' : 'green';
		if (value === 'STOPPED') {
			color = 'volcano';
		} else if (value === 'SENT') {
			color = 'green';
		} else if (value === 'CANCELED') {
			color = 'grey';
		} else if (value === 'NOT_SENT') {
			color = 'grey';
		} else {
			color = 'geekblue';
		}

		return (
			<>
				<Tag color={color} key={value} className={clientListStyles.sourceButton}>
					{value.toUpperCase()}
				</Tag>
			</>
		);
	};

	const setTypeStyle = (value: string) => {
		let color = 'default';
		if (value === 'AUTOMATIC') {
			color = 'cyan';
		} else if (value === 'MANUAL') {
			color = 'lime';
		}
		return (
			<Tag color={color} key={value} className={clientListStyles.sourceButton}>
				{value}
			</Tag>
		);
	};

	return (
		<Modal
			title={
				<Row align="middle">
					<h3 style={{ margin: '16px 16px 16px 0px' }}>Transaction Details</h3>
				</Row>
			}
			visible={showModal}
			onCancel={handleCancel}
			footer={null}
		>
			<div className={clientListStyles.transactionDetailsformContent}>
				<Row>
					<Col span={16}>
						<b>Advance ID</b>
					</Col>
					<Col>{data.advanceId}</Col>
				</Row>
				<Row>
					<Col span={16}>
						<b>Currency</b>
					</Col>
					<Col>{`${getSymbolFromCurrency(currency)} (${currency})`}</Col>
				</Row>
				<Row align="middle">
					<Col span={16}>
						<b>Status</b>
					</Col>
					<Col>
						{setStatusStyle(
							data.goCardlessReqStatus ? data.goCardlessReqStatus : 's'
						)}
					</Col>
				</Row>
				<Row align="middle">
					<Col span={16}>
						<b>Source</b>
					</Col>
					<Col>{setSourceStyle(data.source)}</Col>
				</Row>
				<Row align="middle">
					<Col span={16}>
						<b>Type</b>
					</Col>
					<Col>{setTypeStyle(data.type)}</Col>
				</Row>
				<Row>
					<Col span={16}>
						<b>Total Sale</b>
					</Col>
					<Col>{`${getSymbolFromCurrency(currency)} ${String(
						data.totalSale / 100
					).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</Col>
				</Row>
				<Row>
					<Col span={16}>
						<b>Total Discount</b>
					</Col>
					<Col>{`${getSymbolFromCurrency(currency)} ${String(
						data.totalDiscount / 100
					).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</Col>
				</Row>
				<Row>
					<Col span={16}>
						<b>Total Refunds</b>
					</Col>
					<Col>{`${getSymbolFromCurrency(currency)} ${String(
						data.totalRefund / 100
					).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`}</Col>
				</Row>
				<Row>
					<Col span={16}>
						<b>Repayment Rate</b>
					</Col>
					<Col>{`${data.repaymentRate}%`}</Col>
				</Row>
				<Row>
					<Col span={16}>
						<b>Repayment Value</b>
					</Col>
					<Col>{`${getSymbolFromCurrency(currency)} ${
						data.repaymentRev / 100
					}`}</Col>
				</Row>
				<Row>
					<Col span={16}>
						<b>Created At</b>
					</Col>
					<Col>{moment(data.createdAt).format('YYYY-MM-DD')}</Col>
				</Row>
			</div>
		</Modal>
	);
};

const mapStateToProps = (state: RootState) => ({
	currency: state.auth.loginData.currency,
});

export default connect(mapStateToProps)(RepaymentModal);
