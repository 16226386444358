import React, { useState } from 'react';
import { Col, Row, Collapse } from 'antd';
import ConnectPlatFormCard from '../ConnectPlatformCard/ConnectPlatFormCard';
import connectStyles from 'design/scss/connect.module.scss';
import { STRIPE_OAUTH_URL } from 'config';
import { connect } from 'react-redux';
import { RootState } from 'dto/redux.action.dto';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

interface Props {
	stripe: boolean;
}
const { Panel } = Collapse;
const text = `
We understand the value of your data. That is why we use your information
to provide a bespoke Offer that meets the specific needs of your business.
Valerian is fully compliant with EU GDPR and UK DPPEC regulations, and you
will always have the option to remove our access and ask us to delete your
data. For further information please see our privacy policy.
`;
const StripeCard: React.FC<Props> = ({ stripe }) => {
	return (
		<div>
			<h3 style={{ fontSize: '28px' }}>Connect your data platforms</h3>
			<Collapse
				accordion
				expandIconPosition={'left'}
				className={connectStyles.collapse}
				expandIcon={({ isActive }) =>
					isActive ? (
						<RightOutlined style={{ color: '#ed572f' }} />
					) : (
						<DownOutlined />
					)
				}
			>
				<Panel
					header="How we treat your data"
					key="1"
					className={connectStyles.collapseHeader}
				>
					<p className={connectStyles.customParagraph}>{text}</p>
				</Panel>
			</Collapse>
			<br />
			<div>
				<Row>
					<Col span={12}>
						<span className={connectStyles.textHeader}>Platform Type</span>
					</Col>
					<Col span={12}>
						<span className={connectStyles.textHeader}>Platforms</span>
					</Col>
				</Row>
				<hr />
				{/* payment */}
				<Row>
					<Col span={12} className={connectStyles.columnsStyle}>
						<span className={connectStyles.platformHeaders}>Payments</span>
					</Col>
					<Col span={12}>
						<span className={connectStyles.textHeader}>
							<ConnectPlatFormCard
								platforms={[
									{
										name: 'Stripe',
										image1: require('public/images/newImages/stripe.svg'),
										image2: require('public/images/newImages/stripeTicked.svg'),
										connectLink: STRIPE_OAUTH_URL,
										status: stripe,
										newTab: false,
									},
								]}
							/>
						</span>
					</Col>
				</Row>
			</div>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	stripe: state.auth.loginData.businessPlatform.STRIPE,
});

export default connect(mapStateToProps, null)(StripeCard);
