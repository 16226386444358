/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { Moment } from 'moment';
import { store } from 'store';
import { isEqual } from 'lodash';
import { message } from 'antd';
import { handleLogout } from '../store/actions/authAction';
import moment from 'moment-timezone';
moment.tz.setDefault('Europe/London');

export const requestTableData = async (url: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};

	try {
		let result;
		await axios.get(`${url}?limit=10`, options).then((res) => {
			result = res;
		});

		return result;
	} catch (err) {
		if (err?.response?.status === 401) {
			handleLogout();
		}
		return err;
	}
};

export const requestAdvanceData = async (url: string, secondaryUrl: string) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};

	try {
		let result;

		await axios
			.get(url, options)
			.then(async (res) => {
				let { docs } = res.data;
				let newDocs: typeof docs | [] = [];

				for (let [index, client] of docs.entries()) {
					await axios
						.get(`${secondaryUrl}/${client.clientId}`, options)
						.then(async (advance) => {
							let extraColumns = await advanceSummary(advance.data);

							let v = {
								...docs[index],
								...extraColumns,
							};
							newDocs.push(v);
						});
				}

				result = {
					...res,
					data: {
						...res.data,
						docs: newDocs,
					},
				};
			})
			.catch((err) => {
				if (err.response.data.statusCode === 401) {
					localStorage.clear();
					window.location.href = window.location.origin;
				}
			});

		return result;
	} catch (err) {
		return err;
	}
};

export const requestClientBasicInfo = async (
	url: string,
	secondaryUrl: string
) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};

	try {
		let result;

		await axios
			.get(url, options)
			.then(async (res) => {
				let { docs } = res.data;
				let newDocs: typeof docs = [];

				for (let [index, client] of docs.entries()) {
					await axios
						.get(`${secondaryUrl}?clientId=${client.clientId}`, options)
						.then(async (client) => {
							let extraColumns = {
								companyName: client.data.companyName,
								businessInfo: client.data.businessInfo,
							};

							let v = {
								...extraColumns,
								...docs[index],
							};
							newDocs.push(v);
						});
				}

				result = {
					...res,
					data: {
						...res.data,
						docs: newDocs,
					},
				};
			})
			.catch((err) => {
				if (err.response.data.statusCode === 401) {
					localStorage.clear();
					window.location.href = window.location.origin;
				}
			});

		return result;
	} catch (err) {
		return err;
	}
};

export const advanceSummary = async (
	data: { initialBalance: number; fee: number; totalAmountOutstanding: number }[]
) => {
	let count = data.length,
		total = 0,
		outstanding = 0;

	data.forEach((el) => {
		total += el.initialBalance + el.fee;
		outstanding += el.totalAmountOutstanding;
	});

	return {
		activeAdvance: count,
		totalAdvance: total,
		amountOutstanding: outstanding,
	};
};

export const onPageChange = async (
	url: string,
	page: number,
	date: Date[],
	search: string | null,
	toService?: string | null,
	fromService?: string | null,
	resultService?: string | null
) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		if (date !== null && search !== null) {
			let result;

			if (fromService !== null || toService !== null || resultService !== null) {
				await axios
					.get(
						`${url}?limit=20&page=${page}&${
							resultService !== null ? `result=${resultService}` : ''
						}${toService !== null ? `&reqTo=${toService}` : ''}${
							fromService !== null ? `&reqFrom=${fromService}` : ''
						}&fromDate=
          ${moment(date[0]).format('YYYY-MM-DD')}&toDate=
          ${moment(date[1]).format('YYYY-MM-DD')}`,
						options
					)
					.then((res) => {
						result = res;
					});
			} else {
				await axios
					.get(
						`${url}?limit=20&page=${page}&search=${search}&fromDate=
          ${moment(date[0]).format('YYYY-MM-DD')}&toDate=
          ${moment(date[1]).format('YYYY-MM-DD')}`,
						options
					)
					.then((res) => {
						result = res;
					});
			}

			return result;
		} else if (date !== null) {
			let result;

			if (fromService !== null || toService !== null || resultService !== null) {
				await axios
					.get(
						`${url}?limit=20&page=${page}&${
							resultService !== null ? `result=${resultService}` : ''
						}${toService !== null ? `&reqTo=${toService}` : ''}${
							fromService !== null ? `&reqFrom=${fromService}` : ''
						}&fromDate=
          ${moment(date[0]).format('YYYY-MM-DD')}&toDate=
          ${moment(date[1]).format('YYYY-MM-DD')}`,
						options
					)
					.then((res) => {
						result = res;
					});
			} else {
				await axios
					.get(
						`${url}?limit=20&page=${page}&fromDate=
          ${moment(date[0]).format('YYYY-MM-DD')}&toDate=
          ${moment(date[1]).format('YYYY-MM-DD')}`,
						options
					)
					.then((res) => {
						result = res;
					});
			}

			return result;
		} else if (search !== null) {
			let result;

			if (fromService !== null || toService !== null || resultService !== null) {
				await axios
					.get(
						`${url}?limit=20&page=${page}&${
							resultService !== null ? `result=${resultService}` : ''
						}${toService !== null ? `&reqTo=${toService}` : ''}${
							fromService !== null ? `&reqFrom=${fromService}` : ''
						}&fromDate=
          ${moment(date[0]).format('YYYY-MM-DD')}&toDate=
          ${moment(date[1]).format('YYYY-MM-DD')}`,
						options
					)
					.then((res) => {
						result = res;
					});
			} else {
				await axios
					.get(`${url}?limit=20&page=${page}&search=${search}`, options)
					.then((res) => {
						result = res;
					});
			}

			return result;
		} else {
			let result;

			if (fromService !== null || toService !== null || resultService !== null) {
				await axios
					.get(
						`${url}?limit=20&${
							resultService !== null ? `result=${resultService}` : ''
						}${toService !== null ? `&reqTo=${toService}` : ''}${
							fromService !== null ? `&reqFrom=${fromService}` : ''
						}&page=${page}`,
						options
					)
					.then((res) => {
						result = res;
					});
			} else {
				await axios.get(`${url}?limit=10&page=${page}`, options).then((res) => {
					result = res;
				});
			}

			return result;
		}
	} catch (err) {
		return err;
	}
};

export const onDateChange = async (
	url: string,
	date1: Moment,
	date2: Moment,
	toService?: string | null,
	fromService?: string | null,
	resultService?: string | null
) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		let result;

		if (fromService !== null || toService !== null || resultService !== null) {
			await axios
				.get(
					`${url}?limit=20&${
						resultService !== null ? `result=${resultService}` : ''
					}${toService !== null ? `&reqTo=${toService}` : ''}${
						fromService !== null ? `&reqFrom=${fromService}` : ''
					}&fromDate=
        ${moment(date1).format('YYYY-MM-DD')}&toDate=
        ${moment(date2).format('YYYY-MM-DD')}`,
					options
				)
				.then((res) => {
					result = res;
				});
		} else {
			await axios
				.get(
					`${url}?limit=20&fromDate=
        ${moment(date1).format('YYYY-MM-DD')}&toDate=
        ${moment(date2).format('YYYY-MM-DD')}`,
					options
				)
				.then((res) => {
					result = res;
				});
		}

		return result;
	} catch (err) {
		return err;
	}
};

export const onSearchChange = async (
	url: string,
	date: Date[],
	search: string
) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		if (date !== null) {
			let result;

			await axios
				.get(
					`${url}?limit=20&search=${search}&fromDate=
          ${moment(date[0]).format('YYYY-MM-DD')}&toDate=
          ${moment(date[1]).format('YYYY-MM-DD')}`,
					options
				)
				.then((res) => {
					result = res;
				});

			return result;
		} else {
			let result;

			await axios.get(`${url}?search=${search}&limit=20`, options).then((res) => {
				result = res;
			});

			return result;
		}
	} catch (err) {
		return err;
	}
};

export const changeFromService = async (
	url: string,
	service: string | undefined,
	date: Date[],
	toService: string | null,
	resultService: string | null
) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		if (service === undefined) {
			const result = await axios.get(`${url}?limit=20`, options);
			return result;
		} else if (date !== null) {
			const result = await axios.get(
				`${url}?limit=20&reqFrom=${service}${
					toService !== null ? `&reqTo=${toService}` : ''
				}${resultService !== null ? `&result=${resultService}` : ''}&fromDate=
      ${moment(date[0]).format('YYYY-MM-DD')}&toDate=
      ${moment(date[1]).format('YYYY-MM-DD')}`,
				options
			);
			return result;
		} else {
			const result = await axios.get(
				`${url}?limit=20&reqFrom=${service}${
					toService !== null ? `&reqTo=${toService}` : ''
				}${resultService !== null ? `&result=${resultService}` : ''}`,
				options
			);
			return result;
		}
	} catch (err) {
		message.error(` ${err?.response?.status} Filtering Failed`);
		throw err;
	}
};

export const changeToService = async (
	url: string,
	service: string | undefined,
	date: Date[],
	fromService: string | null,
	resultService: string | null
) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		if (service === undefined) {
			const result = await axios.get(`${url}?limit=20`, options);
			return result;
		} else if (date !== null) {
			const result = await axios.get(
				`${url}?limit=20&reqTo=${service}${
					fromService !== null ? `&reqFrom=${fromService}` : ''
				}${resultService !== null ? `&result=${resultService}` : ''}&fromDate=
      ${moment(date[0]).format('YYYY-MM-DD')}&toDate=
      ${moment(date[1]).format('YYYY-MM-DD')}`,
				options
			);
			return result;
		} else {
			if (fromService !== null && resultService !== null) {
				const result = await axios.get(
					`${url}?limit=20&reqTo=${service}&reqFrom=${fromService}&result=${resultService}`,
					options
				);
				return result;
			} else if (fromService !== null) {
				const result = await axios.get(
					`${url}?limit=20&reqTo=${service}&reqFrom=${fromService}`,
					options
				);
				return result;
			} else if (resultService !== null) {
				const result = await axios.get(
					`${url}?limit=20&reqTo=${service}&result=${resultService}`,
					options
				);
				return result;
			} else {
				const result = await axios.get(`${url}?limit=20&reqTo=${service}`, options);
				return result;
			}
		}
	} catch (err) {
		message.error(` ${err?.response?.status} Filtering Failed`);
		throw err;
	}
};

export const changeResultService = async (
	url: string,
	service: string | undefined,
	date: Date[],
	toService: string | null,
	fromService: string | null
) => {
	const options = {
		headers: {
			Authorization: `Bearer ${store.getState().auth.loginData.accessToken}`,
			'Content-Type': 'application/json',
		},
	};
	try {
		if (service === undefined) {
			const result = await axios.get(`${url}?limit=20`, options);
			return result;
		} else if (date !== null) {
			const result = await axios.get(
				`${url}?limit=20&result=${service}${
					toService !== null ? `&reqTo=${toService}` : ''
				}${fromService !== null ? `&reqFrom=${fromService}` : ''}&fromDate=
      ${moment(date[0]).format('YYYY-MM-DD')}&toDate=
      ${moment(date[1]).format('YYYY-MM-DD')}`,
				options
			);
			return result;
		} else {
			const result = await axios.get(
				`${url}?limit=20&result=${service}${
					toService !== null ? `&reqTo=${toService}` : ''
				}${fromService !== null ? `&reqFrom=${fromService}` : ''}`,
				options
			);
			return result;
		}
	} catch (err) {
		message.error(` ${err?.response?.status} Filtering Failed`);
		throw err;
	}
};

export const numberWithCommas = (num: number) => {
	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
