/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import {
	Row,
	Col,
	Card,
	Button,
	Select,
	Divider,
	Modal,
	Form,
	Input,
	InputNumber,
	Tag,
} from 'antd';
import getSymbolFromCurrency from 'currency-symbol-map';
import { getAdvanceFullData, getOfferInfo } from 'services/dashboard';
import { connect } from 'react-redux';
import AdvanceCardStyles from 'design/scss/advanceCards.module.scss';
import clientListStyles from 'design/scss/clientRepaymentsTable.module.scss';
import moment from 'moment';
import { useForm } from 'antd/lib/form/Form';

const { Option } = Select;
const { TextArea } = Input;

interface Props {
	currency: string;
}

const AdvanceCard: React.FC<Props> = ({ currency }) => {
	//update Advance Modal
	const [isUpdateAdvanceModalVisible, setIsUpdateAdvanceModalVisible] =
		useState(false);

	const [advance, setAdvance] = useState<any>({});
	const [offer, setOffer] = useState<any>(null);
	const [selectedAdvance, setSelectAdvance] = useState<any>(null);
	const [form] = useForm();
	const [totalAmountAdvanced, setTotalAmountAdvanced] = useState<number>(0);
	const [currentOutstanding, setCurrentOutstanding] = useState<number>(0);
	const [totalRepayments, setTotalRepayments] = useState<number>(0);
	const [totalRepaymentsProjection, setTotalRepaymentsProjetion] =
		useState<number>(0);
	const [showMore, setShowMore] = useState<boolean>(false);

	useEffect(() => {
		form.setFieldsValue({
			selectAdvacne: advance.data && advance.data[0].offerId,
		});
		setAdvanceSummary(advance?.data);
	}, [advance]);

	const setAdvanceSummary = (activeAdvance: any[]) => {
		let totalAmountAdvanced = 0,
			currentOutstanding = 0,
			totalRepayments = 0;
		let totalRepaymentProjection = 0;
		for (let i = 0; i < activeAdvance?.length; i++) {
			totalAmountAdvanced +=
				activeAdvance[i]?.initialBalance + activeAdvance[i].fee;
			currentOutstanding += activeAdvance[i]?.totalAmountOutstanding;
			totalRepayments += activeAdvance[i]?.totalRepaid;
			totalRepaymentProjection += activeAdvance[i]?.totalRepaidProjection;
		}
		setTotalAmountAdvanced(totalAmountAdvanced / 100);
		setCurrentOutstanding(currentOutstanding / 100);
		setTotalRepayments(totalRepayments / 100);
		setTotalRepaymentsProjetion(totalRepaymentProjection / 100);
	};

	const setStatusStyle = (value: any) => {
		let color = value.length > 5 ? 'geekblue' : 'green';
		if (value === 'APPROVED') {
			color = 'green';
		} else {
			color = 'volcano';
		}

		return (
			<Tag color={color} key={value} className={clientListStyles.sourceButton}>
				{value.toUpperCase()}
			</Tag>
		);
	};

	const handleCancel = () => {
		setIsUpdateAdvanceModalVisible(false);
	};
	const handleFormSubmit = () => {
		setIsUpdateAdvanceModalVisible(false);
	};

	const getAdvanceData = async () => {
		const data = await getAdvanceFullData();
		if (data?.data?.length !== 0) {
			setSelectAdvance(data.data[0]);
			getOfferData(data.data[0].offerId);
		}
		setAdvance(data);
	};

	const getOfferData = async (id: string) => {
		const data = await getOfferInfo(id);
		if (advance.data) {
			advance.data.map((info: any) => {
				if (info.offerId === id) {
					setSelectAdvance(info);
				}
			});
		}
		setOffer(data.data);
	};

	useEffect(() => {
		getAdvanceData();
	}, []);

	return (
		<>
			<Modal
				title={
					<Row align="middle">
						<h3 style={{ margin: '16px 16px 16px 0px' }}>Update Advance</h3>
					</Row>
				}
				visible={isUpdateAdvanceModalVisible}
				onCancel={handleCancel}
				footer={null}
			>
				<div>
					<Col>
						<h4 style={{ margin: 0 }}>{'Adv-123456'}</h4>
					</Col>
					<Col>
						<Row className={AdvanceCardStyles.Clientmodal_spanContainingRow}>
							<span>
								<b>ID:</b>
							</span>
							<span> Client-123456</span>
						</Row>
					</Col>
				</div>
				<br />
				<div className={AdvanceCardStyles.Clientmodal_spanContainingRow_new}>
					<Row>
						<Col span={10}>
							<b>Current Advance Amount</b>
						</Col>
						<Col span={10}>€572,322.91</Col>
					</Row>
					<Row>
						<Col span={10}>
							<b>Repayment Rate</b>
						</Col>
						<Col span={10}>13%</Col>
					</Row>
					<Row align="middle">
						<Col span={10}>
							<b>Source</b>
						</Col>
						<Col span={10}>
							<div className={AdvanceCardStyles.sourceButton}>Stripe</div>
						</Col>
					</Row>
					<Row>
						<Col span={10}>
							<b>Fee</b>
						</Col>
						<Col span={10}>€5,000.00</Col>
					</Row>
				</div>
				<br />
				<Form
					name="basic"
					layout="vertical"
					requiredMark={false}
					onFinish={handleFormSubmit}
				>
					<Form.Item label="Name" name="name">
						<Input name="name" placeholder="Name" defaultValue={'Advance Value'} />
					</Form.Item>
					<Form.Item label="Description" name="description">
						<TextArea name="description" rows={4} showCount maxLength={1000} />
					</Form.Item>
					<Form.Item label="Status" name="status">
						<Select placeholder="Please Select">
							<Option value="option 1">option 1</Option>
							<Option value="option 2">option 2</Option>
							<Option value="option 3">option 3</Option>
						</Select>
					</Form.Item>

					<Form.Item label="Amount">
						<Input.Group compact>
							<Form.Item name="currency" noStyle>
								<Input style={{ width: '20%' }} name="currency" disabled />
							</Form.Item>
							<Form.Item
								name="amount"
								noStyle
								rules={[
									{
										// required: true,
										message: 'Please input your requested advance amount',
									},
									{
										pattern: /\d+/g,
										message: 'Advance amount can be only digits',
									},
								]}
							>
								<InputNumber
									style={{ width: '80%' }}
									name="amount"
									placeholder="500,000"
									formatter={(value: any) =>
										`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
									}
								/>
							</Form.Item>
						</Input.Group>
					</Form.Item>

					<Form.Item label="Repayment Rate" name="repayment_rate">
						<Input
							name="repayment_rate"
							type={'number'}
							min={1}
							placeholder="repayment rate"
						/>
					</Form.Item>

					<Form.Item label="Fee">
						<Input.Group compact>
							<Form.Item name="currency" noStyle>
								<Input style={{ width: '20%' }} name="currency" disabled />
							</Form.Item>
							<Form.Item
								name="amount"
								noStyle
								rules={[
									{
										// required: true,
										message: 'Please input your requested advance amount',
									},
									{
										pattern: /\d+/g,
										message: 'Advance amount can be only digits',
									},
								]}
							>
								<InputNumber
									style={{ width: '80%' }}
									name="amount"
									placeholder="500,000"
									formatter={(value: any) =>
										`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
									}
								/>
							</Form.Item>
						</Input.Group>
					</Form.Item>

					<Form.Item label="Note" name="notes">
						<TextArea name="notes" rows={4} showCount maxLength={1000} />
					</Form.Item>
					<Row justify="center">
						<Button className="_form_submit_small" type="primary" htmlType="submit">
							Update
						</Button>
					</Row>
				</Form>
			</Modal>

			<Card bordered={false} className="clientDashresponsivework">
				<Row justify="space-between" align="middle">
					<h3>Advances</h3>
				</Row>

				<div className={AdvanceCardStyles.advance_infoCard}>
					<Row>
						<Col span={12} className={AdvanceCardStyles.advance_title}>
							No. of Advances Issued
						</Col>
						<Col span={12} className={AdvanceCardStyles.advance_text}>
							{advance ? advance?.data?.length : 0}
						</Col>
					</Row>
					<Row>
						<Col span={12} className={AdvanceCardStyles.advance_title}>
							Total Amount Advanced
						</Col>
						<Col span={12} className={AdvanceCardStyles.advance_text}>
							{`${getSymbolFromCurrency(currency)}`}
							{` `}
							{`${totalAmountAdvanced}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						</Col>
					</Row>
					<Row>
						<Col span={12} className={AdvanceCardStyles.advance_title}>
							Current Outstanding Balance
						</Col>
						<Col span={12} className={AdvanceCardStyles.advance_text}>
							{`${getSymbolFromCurrency(currency)}`}
							{` `}
							{`${currentOutstanding}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						</Col>
					</Row>
					<Row>
						<Col span={12} className={AdvanceCardStyles.advance_title}>
							Total Repayments Projection{' '}
						</Col>
						<Col span={12} className={AdvanceCardStyles.advance_text}>
							{`${getSymbolFromCurrency(currency)}`}
							{` `}
							{`${totalRepaymentsProjection}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						</Col>
					</Row>
					<Row>
						<Col span={12} className={AdvanceCardStyles.advance_title}>
							Total Repaid
						</Col>
						<Col span={12} className={AdvanceCardStyles.advance_text}>
							{`${getSymbolFromCurrency(currency)}`}
							{` `}
							{`${totalRepayments}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
						</Col>
					</Row>
				</div>
				<br />
				{selectedAdvance !== null && (
					<div className={AdvanceCardStyles.infoContainer}>
						<Row align="middle" justify="space-between">
							<Col span={12}>
								<Row justify="start" align="middle" style={{ width: '60%' }}>
									<h4 style={{ margin: 0 }}>Advance ID:{selectedAdvance.advanceId}</h4>
									{selectedAdvance !== null
										? setStatusStyle(selectedAdvance.status)
										: ''}
									{selectedAdvance !== null ? (
										<Tag
											color={'grey'}
											key={selectedAdvance.repaymentFrequency}
											className={clientListStyles.sourceButton}
											style={{ marginLeft: '.2rem' }}
										>
											{selectedAdvance.repaymentFrequency.toUpperCase()}
										</Tag>
									) : (
										''
									)}
								</Row>
							</Col>
							<Col span={12}>
								<Row justify="end">
									<Form form={form}>
										<Form.Item noStyle name="selectAdvacne">
											<Select
												// defaultValue={advance.data && advance.data[0].offerId}
												style={{ width: 160 }}
												onChange={getOfferData}
											>
												{/* <Option value='option 1'>option 1</Option>
                  <Option value='option 2'>option 2</Option>
                  <Option value='option 3'>option 3</Option> */}
												{advance.data ? (
													advance.data.map((info: any) => (
														<Option value={info.offerId}>{info.advanceId}</Option>
													))
												) : (
													<Option value="option 2" disabled>
														No Advance available
													</Option>
												)}
											</Select>
										</Form.Item>
									</Form>
								</Row>
							</Col>
						</Row>
						{offer !== null && (
							<>
								<Row>
									<div>
										<span className={AdvanceCardStyles.titel}>Created On: </span>
										<span className={AdvanceCardStyles.text}>
											{/* {"March 12, 2021"} */}
											{selectedAdvance !== null &&
												moment(selectedAdvance.createdAt).format('LL')}
										</span>
									</div>
									<div style={{ marginLeft: '1rem' }}>
										<span className={AdvanceCardStyles.titel}>Updated On:</span>
										<span className={AdvanceCardStyles.text}>
											{selectedAdvance !== null &&
												moment(selectedAdvance.updatedAt).format('LL')}
										</span>
									</div>
								</Row>
								<Divider />
								<Row>
									<Col span={12} className={AdvanceCardStyles.NewTitle}>
										Activation Date
									</Col>
									<Col span={12} className={AdvanceCardStyles.NewText}>
										{selectedAdvance !== null && selectedAdvance?.activationDate !== null
											? moment(selectedAdvance?.activationDate).format('YYYY-MM-DD')
											: 'N/A'}
									</Col>
								</Row>
								{/* <Row>
									<Col span={12} className={AdvanceCardStyles.NewTitle}>
										Offer ID
									</Col>
									<Col span={12} className={AdvanceCardStyles.NewText}>
										{offer !== null && offer?.offerId}
									</Col>
								</Row> */}
								{/* <Row>
									<Col span={12} className={AdvanceCardStyles.NewTitle}>
										Name
									</Col>
									<Col span={12} className={AdvanceCardStyles.NewText}>
										{offer !== null && offer?.offer?.name}
									</Col>
								</Row>
								<Row>
									<Col span={12} className={AdvanceCardStyles.NewTitle}>
										Description
									</Col>
									<Col span={12} className={AdvanceCardStyles.NewText}>
										{offer !== null && offer?.offer?.description}
									</Col>
								</Row> */}

								<Row>
									<Col span={12} className={AdvanceCardStyles.NewTitle}>
										Currency
									</Col>
									<Col span={12} className={AdvanceCardStyles.NewText}>
										{selectedAdvance !== null && selectedAdvance?.currency !== null
											? selectedAdvance?.currency
											: 'N/A'}
									</Col>
								</Row>
								{/* <Row>
									<Col span={12} className={AdvanceCardStyles.NewTitle}>
										Repayment Gateway
									</Col>
									<Col span={12} className={AdvanceCardStyles.NewText}>
										{selectedAdvance !== null && selectedAdvance?.repaymentGateway}
									</Col>
								</Row> */}
								<Row>
									<Col span={12} className={AdvanceCardStyles.NewTitle}>
										Amount
									</Col>
									<Col span={12} className={AdvanceCardStyles.NewText}>
										{selectedAdvance !== null &&
											`${
												selectedAdvance?.currency
													? getSymbolFromCurrency(selectedAdvance?.currency)
													: getSymbolFromCurrency(currency)
											} ${String(selectedAdvance.initialBalance / 100).replace(
												/\B(?=(\d{3})+(?!\d))/g,
												','
											)}`}
									</Col>
								</Row>
								{showMore ? (
									<>
										<Row>
											<Col span={12} className={AdvanceCardStyles.NewTitle}>
												Repayment Rate
											</Col>
											<Col span={12} className={AdvanceCardStyles.NewText}>
												{selectedAdvance !== null && selectedAdvance.repaymentRate}%
											</Col>
										</Row>
										<Row>
											<Col span={12} className={AdvanceCardStyles.NewTitle}>
												Total Repayments Projection
											</Col>
											<Col span={12} className={AdvanceCardStyles.NewText}>
												{selectedAdvance !== null &&
													`${
														selectedAdvance?.currency
															? getSymbolFromCurrency(selectedAdvance?.currency)
															: getSymbolFromCurrency(currency)
													} ${String(selectedAdvance.totalRepaidProjection / 100).replace(
														/\B(?=(\d{3})+(?!\d))/g,
														','
													)}`}
											</Col>
										</Row>
										<Row>
											<Col span={12} className={AdvanceCardStyles.NewTitle}>
												Total Repaid
											</Col>
											<Col span={12} className={AdvanceCardStyles.NewText}>
												{selectedAdvance !== null &&
													`${
														selectedAdvance?.currency
															? getSymbolFromCurrency(selectedAdvance?.currency)
															: getSymbolFromCurrency(currency)
													} ${String(selectedAdvance.totalRepaid / 100).replace(
														/\B(?=(\d{3})+(?!\d))/g,
														','
													)}`}
											</Col>
										</Row>
										<Row>
											<Col span={12} className={AdvanceCardStyles.NewTitle}>
												Total to be repaid{' '}
											</Col>
											<Col span={12} className={AdvanceCardStyles.NewText}>
												{selectedAdvance !== null &&
													`${
														selectedAdvance?.currency
															? getSymbolFromCurrency(selectedAdvance?.currency)
															: getSymbolFromCurrency(currency)
													} ${String(selectedAdvance.totalAmountOutstanding / 100).replace(
														/\B(?=(\d{3})+(?!\d))/g,
														','
													)}`}
											</Col>
										</Row>
										<Row>
											<Col span={12} className={AdvanceCardStyles.NewTitle}>
												Fee
											</Col>
											<Col span={12} className={AdvanceCardStyles.NewText}>
												{selectedAdvance !== null &&
													`${
														selectedAdvance?.currency
															? getSymbolFromCurrency(selectedAdvance?.currency)
															: getSymbolFromCurrency(currency)
													} ${String(selectedAdvance.fee / 100).replace(
														/\B(?=(\d{3})+(?!\d))/g,
														','
													)}`}
											</Col>
										</Row>
										{/* <Row>
											<Col span={12} className={AdvanceCardStyles.NewTitle}>
												Minimum Repayment
											</Col>
											<Col span={12} className={AdvanceCardStyles.NewText}>
												{selectedAdvance !== null &&
													`${
														selectedAdvance?.currency
															? getSymbolFromCurrency(selectedAdvance?.currency)
															: getSymbolFromCurrency(currency)
													} ${String(selectedAdvance.minRepayment / 100).replace(
														/\B(?=(\d{3})+(?!\d))/g,
														','
													)}`}
											</Col>
										</Row>
										<Row>
											<Col span={12} className={AdvanceCardStyles.NewTitle}>
												Maximum Repayment
											</Col>
											<Col span={12} className={AdvanceCardStyles.NewText}>
												{selectedAdvance !== null &&
													`${
														selectedAdvance?.currency
															? getSymbolFromCurrency(selectedAdvance?.currency)
															: getSymbolFromCurrency(currency)
													} ${String(selectedAdvance.minRepayment / 100).replace(
														/\B(?=(\d{3})+(?!\d))/g,
														','
													)}`}
											</Col>
										</Row>
										<Row>
											<Col span={12} className={AdvanceCardStyles.NewTitle}>
												Holiday Period
											</Col>
											<Col span={12} className={AdvanceCardStyles.NewText}>
												{selectedAdvance !== null && selectedAdvance?.holidayPeriod}
											</Col>
										</Row>
										<Row>
											<Col span={12} className={AdvanceCardStyles.NewTitle}>
												Revenue Source
											</Col>
											<Col span={12} className={AdvanceCardStyles.NewText}>
												{selectedAdvance !== null && selectedAdvance?.revSources?.length > 0
													? selectedAdvance?.revSources?.map(
															(data: { platform: string; name: string }) => (
																<Tag color={data.platform === 'SHOPIFY' ? 'green' : 'blue'}>
																	{data.platform} - {data.name ? data.name : 'N/A'}
																</Tag>
															)
													  )
													: 'N/A'}
											</Col>
										</Row>
										<Row>
											<Col span={12} className={AdvanceCardStyles.NewTitle}>
												Payment Start Date
											</Col>
											<Col span={12} className={AdvanceCardStyles.NewText}>
												{selectedAdvance !== null && selectedAdvance?.fulfilledDate !== null
													? moment(selectedAdvance?.paymentStartDate).format('YYYY-MM-DD')
													: 'N/A'}
											</Col>
										</Row> */}
										<Row>
											<Col span={12} className={AdvanceCardStyles.NewTitle}>
												Fulfilled Date
											</Col>
											<Col span={12} className={AdvanceCardStyles.NewText}>
												{selectedAdvance !== null && selectedAdvance?.fulfilledDate !== null
													? moment(selectedAdvance?.fulfilledDate).format('YYYY-MM-DD')
													: 'N/A'}
											</Col>
										</Row>
										<p
											onClick={() => {
												setShowMore(false);
											}}
											style={{ cursor: 'pointer' }}
										>
											{' '}
											Show Less...
										</p>
									</>
								) : (
									<p
										onClick={() => {
											setShowMore(true);
										}}
										style={{ cursor: 'pointer' }}
									>
										Show More....
									</p>
								)}
								<br />
							</>
						)}
					</div>
				)}
			</Card>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	currency: state.auth.loginData.currency,
});

export default connect(mapStateToProps)(AdvanceCard);
