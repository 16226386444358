/* eslint-disable @typescript-eslint/no-explicit-any */
import { InboxOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, message, Row } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import axios from 'axios';
import { BASE_URL } from 'config';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { updateClientCurrentState } from 'store/actions/authAction';
import connectStyles from 'design/scss/connect.module.scss';
import { setCheckFinancial } from 'store/actions/dashBoardActions';
import { getCodatConnection } from 'services/dashboard';
import {
	BANKING_PLATFORM_ARRAY,
	CodatConnectionType,
	FINANCIAL_PLATFORM_ARRAY,
} from 'utils/variables';
import AddModal from 'components/ConnectPlatformCard/AddModal';
import ConnectItem from 'components/ConnectPlatformCard/ConnectItem';

interface Props {
	clientId: string;
	fileType: string;
	accessToken: string | null;
	submitForm: () => void;
	checked: boolean;
	forceStates: string[] | null;
	updateClientCurrentState: (state: string, force: boolean) => void;
	setChecked: (checked: boolean) => void;
}

const UploadFinancial: React.FC<Props> = ({
	clientId,
	fileType,
	forceStates,
	submitForm,
	accessToken,
	updateClientCurrentState,
	checked,
	setChecked,
}) => {
	const [progress, setProgress] = useState<number>(0);
	const [files, setFiles] = useState<any>([]);
	const [addModal, setAddModal] = useState<boolean>(false);
	const [platforms, setPlatForms] = useState<any>([]);

	const clickAdd = (platforms: any) => {
		setPlatForms(platforms);
		setAddModal(true);
	};

	const props = {
		name: 'file',
		multiple: true,
		customRequest: async (options: any) => {
			const { onSuccess, file, onProgress } = options;

			const fmData = new FormData();
			const config = {
				headers: {
					'Content-type': 'multipart/form-data',
					Authorization: `Bearer ${accessToken}`,
				},
				onUploadProgress: (event: any) => {
					const percent = Math.floor((event.loaded / event.total) * 100);
					setProgress(percent);
					if (percent === 100) {
						setTimeout(() => setProgress(0), 1000);
					}
					onProgress({ percent: (event.loaded / event.total) * 100 }, file);
				},
			};
			fmData.append('file', file);
			fmData.append('clientId', clientId);
			fmData.append('fileType', fileType);
			try {
				const res = await axios.post(`${BASE_URL}upload/file`, fmData, config);
				onSuccess(res, file);
			} catch (err) {
				//
			}
		},
		onChange(info: any) {
			const { status } = info.file;
			if (status !== 'uploading') {
				//
			}
			if (status === 'done') {
				if (files.length === 0) {
					setFiles([info.file.response.url]);
				} else {
					const newFileList = `${files[0]},${info.file.response.url}`;
					setFiles([newFileList]);
				}
				message.success(`${info.file.name} file uploaded successfully.`);
			} else if (status === 'error') {
				message.error(`${info.file.name} file uploaded failed.`);
			}
		},
	};

	return (
		<>
			<AddModal
				visible={addModal}
				handleCancel={() => setAddModal(false)}
				title={
					platforms === BANKING_PLATFORM_ARRAY
						? 'Banking Platforms'
						: 'Accounting Platfroms'
				}
				allData={platforms}
			/>
			<Row>
				<div className={connectStyles.LabelText}>
					<h4>Connect your bank accounts</h4>
					<Col span={20}>
						<Row style={{ padding: '2rem' }}>
							<Col style={{ marginRight: '1rem' }}>
								<ConnectItem
									name="Open Banking"
									img={require('public/images/newImages/gocardless.svg')}
									connectionLink="http://localhost:3495"
								/>
							</Col>
							<Col style={{ alignItems: 'center' }}>
								<ConnectItem
									name="Add"
									img={require('public/images/newImages/Button.svg')}
									action={() => clickAdd(FINANCIAL_PLATFORM_ARRAY)}
								/>
							</Col>
						</Row>
					</Col>
					<br />
					{/* <Col span={20}>
						<b>Banking Platforms:</b>
						<Row justify="space-between" style={{ padding: '2rem' }}>
							<Col>
								<ConnectItem
									name="HSBC"
									img={require('public/images/newImages/HSBC.svg')}
									action={() => getCodatConnection(CodatConnectionType.HSBC)}
								/>
							</Col>
							<Col>
								<ConnectItem
									name="BARCLAY_CARD"
									img={require('public/images/newImages/BARCLAYS.svg')}
									action={() => getCodatConnection(CodatConnectionType.BARCLAY_CARD)}
								/>
							</Col>
							<Col>
								<ConnectItem
									name="NATWEST"
									img={require('public/images/newImages/NATWEST.svg')}
									action={() => getCodatConnection(CodatConnectionType.NATWEST)}
								/>
							</Col>

							<Col>
								<ConnectItem
									name="Add"
									img={require('public/images/newImages/Button.svg')}
									action={() => clickAdd(BANKING_PLATFORM_ARRAY)}
								/>
							</Col>
						</Row>
					</Col>
					<br /> */}
					<h4>Don't see your platforms above? Upload your financials instead</h4>
				</div>
			</Row>

			<Row justify="center">
				<div>
					<p className={connectStyles.newfinancialparagraph}>
						Please provide monthly financial data (
						<strong style={{ color: '#000' }}>income statement</strong> and{' '}
						<strong style={{ color: '#000' }}>balance sheet</strong>) for at least the
						past year, in a month-to-month format. This should be in an editable
						format (xlsx, csv, etc). A template is included below for your
						convenience.
					</p>
					<br />
					<p>
						<a href="https://valerian-funds.fra1.digitaloceanspaces.com/guidelines/Template/Valerian%20-%20Financial%20Statement%20Template.xlsx">
							Template for monthly financial data
						</a>
					</p>
				</div>
				<br />
				<Form
					id="upload-financials-form"
					className={connectStyles.uploadForm}
					onFinish={() => submitForm()}
				>
					<Form.Item
						label=""
						name="input files"
						rules={[
							{
								required: true,
								message: 'No files have been uploaded yet',
							},
						]}
					>
						<Dragger {...props}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined required style={{ color: '#3153b2' }} />
							</p>
							<p className="ant-upload-text draggerText spaceFont">
								Upload monthly financial data here
							</p>
							<p className="ant-upload-hint draggerText spaceFont">
								Click or drag file to this area
							</p>
						</Dragger>
					</Form.Item>
					<Form.Item name="marketingPermission">
						<Checkbox
							className={connectStyles.newfinancialparagraph}
							name="marketingPermission"
							defaultChecked={checked}
							checked={checked}
							onChange={(e: any) => {
								setChecked(e.target.checked);
							}}
						>
							<b>I have connected or uploaded my financial data</b>
						</Checkbox>
					</Form.Item>
					<Row gutter={16}>
						{forceStates.length === 0 && (
							<Col>
								<Button
									type="primary"
									className="_form_submit_small noColor"
									onClick={() => updateClientCurrentState('CONNECT_PLATFORMS', true)}
								>
									Previous
								</Button>
							</Col>
						)}
						<Col>
							<Button
								type="primary"
								className="_form_submit_small"
								// htmlType="submit"
								onClick={() => updateClientCurrentState('KYC', false)}
								disabled={files.length > 0 || checked ? false : true}
							>
								Next
							</Button>
						</Col>
					</Row>
				</Form>
			</Row>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	clientId: state.auth.loginData.clientId,
	accessToken: state.auth.loginData.accessToken,
	forceStates: state.auth.loginData.forceStates,
	checked: state.dash.checkFinancial,
});

const mapPropsToState = (dispatch: any) => ({
	updateClientCurrentState: (currentState: string, force: boolean) => {
		return dispatch(updateClientCurrentState(currentState, force));
	},
	setChecked: (checked: boolean) => {
		return dispatch(setCheckFinancial(checked));
	},
});

export default connect(mapStateToProps, mapPropsToState)(UploadFinancial);
