/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row } from 'antd';
import React from 'react';
import ConnectItem from './ConnectItem';
interface Props {
	platforms: any[];
	name?: string;
}
const ConnectPlatFormModal: React.FC<Props> = ({ platforms, name }) => {
	return (
		<>
			<b>{name}</b>
			<Col span={20}>
				<Row
					justify="space-between"
					style={{
						padding: '2rem',
					}}
				>
					{platforms &&
						platforms.map((platform: any, i: number) => (
							<Col>
								<ConnectItem
									name={platform.name}
									action={platform.action}
									img={platform.image1}
									connectionLink={platform.connectLink && platform.connectLink}
								/>
							</Col>
						))}
				</Row>
			</Col>
		</>
	);
};

export default ConnectPlatFormModal;
