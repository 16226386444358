import React from 'react';
//ant d
import { Row } from 'antd';
//css
import stepStyles from '../../design/scss/allOfferComponents.module.scss';

interface StepHeaderProps {
	description: string;
}

const StepHeader: React.FC<StepHeaderProps> = (props: StepHeaderProps) => {
	return (
		<React.Fragment>
			<Row>
				<p className={stepStyles.stepHeaderParagraph}>{props.description}</p>
			</Row>
		</React.Fragment>
	);
};

export default StepHeader;
