/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, message, Row } from 'antd';
import { connect } from 'react-redux';
import Link from 'next/link';
import {
	clearMsg,
	contact_info_onChange,
	register_client,
} from 'store/actions/authAction';

interface Props {
	authContact: any;
	clearMsg: () => void;
	changeContactInfo: (data: any) => void;
	goPrevious: () => void;
	register_client: () => void;
	errMsg: any;
	passWord: string;
}

const RegisterForm: React.FC<Props> = ({
	authContact,
	clearMsg,
	changeContactInfo,
	goPrevious,
	register_client,
	errMsg,
}) => {
	const [agreement, serAgreement] = useState(false);

	useEffect(() => {
		if (errMsg) {
			message.error(errMsg);
		}
		clearMsg();
	}, [errMsg]);

	const onFinish = async (value: any) => {
		if (
			!value.password.match(
				/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/
			)
		) {
			message.error(
				'The password must have at least one capital letter and a number'
			);
		} else {
			register_client();
		}
	};
	return (
		<>
			<h3>Create your account</h3>
			<Form
				id="Registration-Form-3"
				name="basic"
				layout="vertical"
				requiredMark={false}
				onFinish={onFinish}
				style={{ textAlign: 'start', paddingTop: '1.6rem' }}
				initialValues={{
					['email']: authContact.email,
				}}
			>
				<Form.Item label="Email" name="email">
					<Input name="email" type="email" disabled />
				</Form.Item>

				<Form.Item
					name="password"
					label="Password"
					required={true}
					rules={[
						{
							required: true,
							message: 'Please input your password',
						},
						{
							pattern: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
							message:
								'Please ensure your password contains at least one lower case letter, one capital letter and one number',
						},
						({ getFieldValue }) => ({
							validator(_) {
								if (
									getFieldValue('password').length >= 1 &&
									getFieldValue('password').length < 8
								) {
									return Promise.reject('Password must be atleast 8 characters long!');
								}
								return Promise.resolve();
							},
						}),
					]}
					hasFeedback
				>
					<Input.Password
						minLength={8}
						name="password"
						onChange={(e: any) => changeContactInfo(e)}
						placeholder="Enter Password"
					/>
				</Form.Item>

				<Form.Item
					name="confirm_password"
					label="Confirm Password"
					dependencies={['password']}
					hasFeedback
					rules={[
						{
							required: true,
							message: 'Please enter your password',
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (!value || getFieldValue('password') === value) {
									return Promise.resolve();
								}
								return Promise.reject(
									'The two passwords that you entered do not match'
								);
							},
						}),
					]}
				>
					<Input.Password
						name="confirm_password"
						placeholder="Reenter Password"
						minLength={8}
					/>
				</Form.Item>

				<Form.Item>
					<Checkbox checked={agreement} onChange={() => serAgreement(!agreement)}>
						I have read and agree to Valerian’s{' '}
						<Link href="https://valerianfunds.com/privacy-policy/">
							<a target="_blank">Privacy Policy</a>
						</Link>{' '}
						and{' '}
						<Link href="https://valerianfunds.com/terms-of-use/">
							<a target="_blank">Terms of Use</a>
						</Link>
						.
					</Checkbox>
				</Form.Item>

				<Form.Item>
					<Row>
						<Col
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Button
								className="_previous_small"
								type="primary"
								onClick={() => goPrevious()}
							>
								Previous
							</Button>
						</Col>
						<Col>
							<Button
								className="_form_submit_small"
								type="primary"
								htmlType="submit"
								disabled={!agreement}
							>
								Sign Up
							</Button>
						</Col>
					</Row>
				</Form.Item>
			</Form>
		</>
	);
};

const mapStateToProps = (state: any) => ({
	authContact: state.auth.contactInfo,
	errMsg: state.auth.errMsg,
	passWord: state.auth.contactInfo.password,
});

const mapDispatchToProps = (dispatch: any) => ({
	changeContactInfo: (data: any) => {
		dispatch(contact_info_onChange(data));
	},
	register_client: () => {
		dispatch(register_client());
	},
	clearMsg: () => {
		dispatch(clearMsg());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
