import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { connect } from 'react-redux';
import { handleLogout } from 'store/actions/authAction';
import { clearData } from 'store/actions/dashBoardActions';
import { setDeploymentKey } from 'store/actions/settingsActions';
import { DEPLOYMENT_KEY } from 'config';
import { Layout } from 'antd';
import { TopNav } from 'components';
import { checkLoginData } from 'store/actions/authAction';
import { RootState } from 'dto/redux.action.dto';
import dashboardStyles from 'design/scss/dashboard.module.scss';

import * as Sentry from '@sentry/nextjs';

interface OnboardingLayoutProps {
	title: string;
	isLoggedIn: boolean;
	loggedInUser: string;
	currentState: string | null;
	forceStates: Array<string>;
	children: JSX.Element;
	checkLoginData: () => void;
	deploymentKey: string | null;
	logOut: () => void;
	clearDashboard: () => void;
	setDeploymentKey: (deploymentKey: string) => void;
}

const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({
	title,
	isLoggedIn,
	loggedInUser,
	currentState,
	forceStates,
	children,
	checkLoginData,
	deploymentKey,
	logOut,
	clearDashboard,
	setDeploymentKey,
}) => {
	const router = useRouter();

	const checkDeployment = () => {
		if (DEPLOYMENT_KEY !== deploymentKey) {
			clearDashboard();
			logOut();
			setDeploymentKey(DEPLOYMENT_KEY);
		}
	};

	useEffect(() => {
		checkDeployment();
		if (!isLoggedIn) {
			router.push('/auth/login');
		}
	}, [isLoggedIn]);

	useEffect(() => {
		checkLoginData();
		Sentry.setUser({ user: loggedInUser });
	}, []);

	useEffect(() => {
		if (
			forceStates &&
			forceStates.length > 0 &&
			router.pathname === '/google-auth-callback'
		) {
			return;
		} else if (forceStates && forceStates.length > 0) {
			router.push('/force-state');
		} else if (currentState === 'DASHBOARD') {
			router.push('/dashboard');
		}
	}, [currentState, forceStates]);

	return (
		<React.Fragment>
			<Head>
				<title>Valerian | {title}</title>
			</Head>

			<main>
				<Layout className={dashboardStyles.dashboard}>
					<Layout.Header className={dashboardStyles.dashboard_topnav}>
						<TopNav />
					</Layout.Header>

					<Layout className={dashboardStyles.dashboard_body}>
						<Layout.Content className={dashboardStyles.dashboard_body_content}>
							{children}
						</Layout.Content>
					</Layout>
				</Layout>
			</main>
		</React.Fragment>
	);
};

const mapStateToProps = (state: RootState) => ({
	isLoggedIn: state.auth.loginData.isLoggedIn,
	loggedInUser: state.auth.loginData.email,
	currentState: state.auth.loginData.currentState,
	forceStates: state.auth.loginData.forceStates,
	deploymentKey: state.settings.deploymentKey,
});

const mapDispatchToProps = (dispatch: any) => ({
	checkLoginData: () => dispatch(checkLoginData()),
	setDeploymentKey: (deploymentKey: string) => {
		dispatch(setDeploymentKey(deploymentKey));
	},
	logOut: () => dispatch(handleLogout()),
	clearDashboard: () => dispatch(clearData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingLayout);
