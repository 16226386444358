/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Input, message, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import axios from 'axios';
import { BASE_URL } from 'config';
import { connect } from 'react-redux';
import { updateForceState } from 'services/dashboard';
import {
	checkLoginData,
	updateClientCurrentState,
} from 'store/actions/authAction';
import connectStyles from 'design/scss/connect.module.scss';
import { setCheckKYC } from 'store/actions/dashBoardActions';

interface Props {
	clientId: string;
	cName: string | null;
	accessToken: string | null;
	updateClientCurrentState: (curerntState: string, force: boolean) => void;
	forceStates: Array<string>;
	checkLoginData: () => void;
	kyc: any;
	fName: string | null;
	lName: string | null;
	checked: boolean;
	setChecked: (checked: boolean) => void;
}

const KYCForm: React.FC<Props> = ({
	clientId,
	cName,
	accessToken,
	updateClientCurrentState,
	forceStates,
	checkLoginData,
	kyc,
	fName,
	lName,
	checked,
	setChecked,
}) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [progress, setProgress] = useState<number>(0);
	// const [checked, setChecked] = useState<boolean>(false);
	const [files, setFiles] = useState<any>([]);
	const [form] = useForm();

	useEffect(() => {
		form.setFieldsValue({ ...kyc });
	}, [kyc]);

	const props = {
		name: 'file',
		multiple: true,
		customRequest: async (options: any) => {
			const { onSuccess, file, onProgress } = options;

			const fmData = new FormData();
			const config = {
				headers: {
					'content-type': 'multipart/form-data',
					Authorization: `Bearer ${accessToken}`,
				},
				onUploadProgress: (event: any) => {
					const percent = Math.floor((event.loaded / event.total) * 100);
					setProgress(percent);
					if (percent === 100) {
						setTimeout(() => setProgress(0), 1000);
					}
					onProgress({ percent: (event.loaded / event.total) * 100 }, file);
				},
			};
			fmData.append('file', file);
			fmData.append('clientId', clientId);
			fmData.append('fileType', 'KYC');
			try {
				const res = await axios.post(`${BASE_URL}upload/file`, fmData, config);
				onSuccess(res, file);
			} catch (err) {
				return err;
			}
		},
		onChange(info: any) {
			const { status } = info.file;
			if (status !== 'uploading') {
				//
			}
			if (status === 'done') {
				if (files.length === 0) {
					setFiles([info.file.response.url]);
				} else {
					const newFileList = `${files[0]},${info.file.response.url}`;
					setFiles([newFileList]);
				}
				message.success(`${info.file.name} file uploaded successfully.`);
			} else if (status === 'error') {
				message.error(`${info.file.name} file uploaded failed.`);
			}
		},
	};

	const onFinish = async (values: any) => {
		const data = {
			kyc: {
				firstName: values.firstName,
				lastName: values.lastName,
				companyName: values.companyName,
				businessRegNo: values.businessRegNo,
			},
		};
		const options = {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'Content-Type': 'application/json',
			},
		};
		try {
			await axios.patch(`${BASE_URL}client/update`, data, options);
			message.success('KYC updated');

			if (forceStates.length > 0 && forceStates[0] === 'KYC') {
				await updateForceState('KYC');
				checkLoginData();
			} else {
				updateClientCurrentState('CONNECTION_DONE', false);
			}
		} catch (err) {
			message.error('Could not save Your KYC');
		}
	};

	return (
		<Row>
			<p className={connectStyles.newkycparagraph}>
				Please provide details of the authorised signatory for {cName}
			</p>
			<Form
				id="upload-Kyc-form"
				className={connectStyles.uploadForm}
				onFinish={onFinish}
				layout="vertical"
				requiredMark={false}
				form={form}
				initialValues={{
					['firstName']: fName,
					['lastName']: lName,
				}}
			>
				{/* first name last name */}
				<Row>
					<Col span={11}>
						<Form.Item
							label="First Name"
							name="firstName"
							className="spaceFont"
							rules={[
								{
									required: true,
									message: 'Please input your first name',
								},
							]}
						>
							<Input name="firstName" />
						</Form.Item>
					</Col>
					<Col span={11} offset={2}>
						<Form.Item
							label="Last Name"
							name="lastName"
							className="spaceFont"
							rules={[
								{
									required: true,
									message: 'Please input your last name',
								},
							]}
						>
							<Input name="lastName" />
						</Form.Item>
					</Col>
				</Row>
				{/*Role*/}
				<Form.Item
					label="Job Title"
					name="companyName"
					className="spaceFont"
					rules={[
						{
							required: true,
							message: 'Please input your Job Title',
						},
					]}
				>
					<Input name="companyName" />
				</Form.Item>
				{/* Company Reg Number */}
				<Form.Item
					label="Company Registration Number"
					name="businessRegNo"
					className="spaceFont"
					rules={[
						{
							required: true,
							message: 'Please input your Business Reg.no!',
						},
					]}
				>
					<Input name="businessRegNo" />
				</Form.Item>
				{/* Dragger */}
				<Form.Item
					label="Photo ID"
					name="image"
					className="spaceFont"
					rules={[
						{
							required: checked ? false : true,
							message: 'No files have been uploaded yet.',
						},
					]}
				>
					<Dragger {...props}>
						<p className="ant-upload-drag-icon">
							<InboxOutlined required style={{ color: '#3153b2' }} />
						</p>
						<p className="ant-upload-text draggerText spaceFont">
							<strong>
								Upload personal Photo ID (Passport, Driving Licence, ID Card)
							</strong>
						</p>
						<p className="ant-upload-hint draggerText spaceFont">
							Click or drag file to this area to upload
						</p>
					</Dragger>
				</Form.Item>
				<Form.Item name="skipKYCUpload">
					<Checkbox
						className={connectStyles.newfinancialparagraph}
						name="skipKYCUpload"
						defaultChecked={checked}
						checked={checked}
						onChange={(e: any) => {
							setChecked(e.target.checked);
						}}
					>
						<b>I have uploaded my KYC documents</b>
					</Checkbox>
				</Form.Item>
				{/* Button*/}
				<Row gutter={16}>
					{forceStates.length === 0 && (
						<Col>
							<Button
								type="primary"
								className="_form_submit_small noColor"
								onClick={() => updateClientCurrentState('FINANCIAL', true)}
							>
								Previous
							</Button>
						</Col>
					)}
					<Col>
						<Button
							type="primary"
							className="_form_submit_small"
							htmlType="submit"
							disabled={files.length > 0 || checked ? false : true}
						>
							Finish
						</Button>
					</Col>
				</Row>
			</Form>
		</Row>
	);
};

const mapStateToProps = (state: any) => ({
	clientId: state.auth.loginData.clientId,
	cName: state.auth.loginData.companyName,
	accessToken: state.auth.loginData.accessToken,
	forceStates: state.auth.loginData.forceStates,
	fName: state.auth.loginData.firstName,
	lName: state.auth.loginData.lastName,
	checked: state.dash.checkKYC,
});

const mapDispatchToProps = (dispatch: any) => ({
	updateClientCurrentState: (currentState: string, force: boolean) => {
		return dispatch(updateClientCurrentState(currentState, force));
	},
	setChecked: (checked: boolean) => {
		return dispatch(setCheckKYC(checked));
	},
	checkLoginData: () => dispatch(checkLoginData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(KYCForm);
