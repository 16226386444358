import { Modal } from 'antd';
import React from 'react';

interface Props {
	googleModal: boolean;
	handleCancel: () => void;
}

const GoogleModal: React.FC<Props> = ({ googleModal, handleCancel }) => {
	return (
		<Modal
			title="Google Analytics Connect"
			visible={googleModal}
			onCancel={handleCancel}
			footer={false}
		>
			<p style={{ fontSize: '18px' }}>
				<strong>Connection to Google Analytics:</strong>
			</p>
			<p>
				In order for us to get a full understanding of your business, we need to
				have access to your marketing data. We therefore ask that you add{' '}
				<a href="mailto:data17@valerianfunds.com">data17@valerianfunds.com</a> as a
				user within your Google Analytics account. We require read-only access to
				your data, and you will be able to revoke our access at any time.
			</p>
			<br />
			<p>
				<a href="https://analytics.google.com/analytics/web/">
					https://analytics.google.com/analytics/web/
				</a>
			</p>
			<ol>
				<li>
					<p>
						Click Admin on the bottom left, and navigate to the desired account from
						dropdown
					</p>
				</li>
				<li>
					<p>In the Account column select “Account User Management”</p>
				</li>
				<li>
					<p>Click + button in top right, and select “Add users”</p>
				</li>
				<li>
					<p style={{ fontSize: '18px' }}>
						Enter <strong>data17@valerianfunds.com</strong>
					</p>
				</li>
				<li>
					<p>Check only “Read & Analyse” in Permissions</p>
				</li>
				<li>
					<p>Click Add on top right</p>
				</li>
			</ol>
		</Modal>
	);
};

export default GoogleModal;
