import React, { useState } from 'react';
import { Col, Row, Collapse } from 'antd';
import connectStyles from 'design/scss/connect.module.scss';
import { connect } from 'react-redux';
import { RootState } from 'dto/redux.action.dto';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import GoogleModal from 'components/ConnectPlatformCard/GoogleModal';
import ConnectPlatFormModal from 'components/ConnectPlatformCard/ConnectPlatFormModal';
import { GOOGLE_ADS_CLIENT_ID, GOOGLE_ADS_REDIRECT_URI } from 'config';

interface Props {
	googleAdsBeta?: boolean;
}
const { Panel } = Collapse;
const text = `
We understand the value of your data. That is why we use your information
to provide a bespoke Offer that meets the specific needs of your business.
Valerian is fully compliant with EU GDPR and UK DPPEC regulations, and you
will always have the option to remove our access and ask us to delete your
data. For further information please see our privacy policy.
`;

const GoogleAdsCard: React.FC<Props> = ({ googleAdsBeta }) => {
	const [googleModal, setGoogleVisible] = useState(false);

	const handleCancel = () => {
		setGoogleVisible(false);
	};
	return (
		<div>
			<GoogleModal googleModal={googleModal} handleCancel={handleCancel} />
			<h3 style={{ fontSize: '28px' }}>Connect your data platforms</h3>
			<Collapse
				accordion
				expandIconPosition={'left'}
				className={connectStyles.collapse}
				expandIcon={({ isActive }) =>
					isActive ? (
						<RightOutlined style={{ color: '#ed572f' }} />
					) : (
						<DownOutlined />
					)
				}
			>
				<Panel
					header="How we treat your data"
					key="1"
					className={connectStyles.collapseHeader}
				>
					<p className={connectStyles.customParagraph}>{text}</p>
				</Panel>
			</Collapse>
			<br />
			<div>
				<Row>
					<Col span={12}>
						<span className={connectStyles.textHeader}>Platform Type</span>
					</Col>
					<Col span={12}>
						<span className={connectStyles.textHeader}>Platforms</span>
					</Col>
				</Row>
				<hr />
				{/* Marketing */}
				<Row>
					<Col span={12} className={connectStyles.columnsStyle}>
						<span className={connectStyles.platformHeaders}>Marketing</span>
					</Col>
					<Col span={12}>
						<span className={connectStyles.textHeader}>
							<ConnectPlatFormModal
								platforms={[
									{
										image1: require('public/images/newImages/googleAds.svg'),
										image2: require('public/images/newImages/googleAdsTicked.svg'),
										connectLink: `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/adwords&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${GOOGLE_ADS_REDIRECT_URI}&client_id=${GOOGLE_ADS_CLIENT_ID}`,
										status: googleAdsBeta,
										newTab: null,
									},
									// {
									// 	image1: require('public/images/newImages/googleAds.svg'),
									// 	image2: require('public/images/newImages/googleAdsTicked.svg'),
									// 	action: clickGoogle,
									// 	status: false,
									// 	newTab: false,
									// },
								]}
							/>
						</span>
					</Col>
				</Row>
			</div>
		</div>
	);
};

const mapStateToProps = (state: RootState) => ({
	googleAdsBeta: state.auth.loginData.marketingPlatform.GOOGLE_ADS,
});

export default connect(mapStateToProps, null)(GoogleAdsCard);
